// import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MDButton from 'components/MDButton';
// import MDInput from "components/MDInput";
// import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import Select from 'react-select';
import * as moment from 'moment';
import * as service from '../../../services/service';

function ApprovedRejectedEstimates() {   
    const [workStatusData, setWorkStatusData] = useState([]);
    const [errors, setErrors] = useState({});
    // const [loading, setLoading] = useState(false);    

    const getWorkStatusDetails = () => {
        const errs = {};
        fetch(
            service.GET_APPROVED_REJECTED_WORK_STATUS,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json())
            .then(result => {
                setWorkStatusData(result);
            })
            .catch(err => {
                if (err) {
                    errs[".invalid"] = err;
                    setErrors(err);
                };
            });
    };

    useEffect(() => {
        // setLoading(true);
        getWorkStatusDetails();
        // setLoading(false);
    }, []);

    const handleDownload = (fileName, filePath) => {
        // setLoading(true);
        const formData = new FormData();
        formData.append("FileName", fileName);
        formData.append("FilePath", filePath);
        fetch(
            service.DOWNLOAD_FILE,
            {
                method: 'POST',
                body: formData
            })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    fileName,
                );
                // Append to html link element page
                document.body.appendChild(link);
                // Start download
                link.click();
                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });

        // setLoading(false);
    };    

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Estimations (Approved/Rejected)
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center', }}>
                                    <Table aria-label="simple table">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan="2" align="center">
                                                    <Table aria-label="simple table">
                                                        <TableRow style={{ backgroundColor: 'lightgrey' }}>                                                            
                                                            <TableCell style={{ fontWeight: 'bold' }}>Division</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Sub Division</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Project</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Gate Type</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Gate Number</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Hoist Type</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Hoist Number</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Work Description</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Work Date</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Remarks</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Task</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Sub Task</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Sub Sub Task</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Stage</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Stage Remarks</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Stage Date</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Remarks</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Amount</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>File1 </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>File2</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>File3</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>File4</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>File5</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Approved/Rejected By</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Date</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Remarks</TableCell>
                                                        </TableRow>
                                                        <TableBody>
                                                            {workStatusData.map((row) => (
                                                                <TableRow
                                                                    key={row.workId}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >                                                                   
                                                                    <TableCell component="th" scope="row">{row.mechanicalDivision}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.mechanicalSubDivision}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.project}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.gateType}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.gateNumber}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.hoistType}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.hoistNumber}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.workDescription}</TableCell>
                                                                    <TableCell component="th" scope="row">{moment(row.workDate).format('DD/MM/YYYY')}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.remarks}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.task}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.subTask}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.subSubTask}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.workStage}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.workStageRemarks}</TableCell>
                                                                    <TableCell component="th" scope="row">{moment(row.statusDate).format('DD/MM/YYYY')}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.remarks}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.amount}</TableCell>
                                                                    <TableCell>
                                                                        <MDButton onClick={() => handleDownload(row.fileName1, row.filePath1)}>{row.fileName1}</MDButton>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDButton onClick={() => handleDownload(row.fileName2, row.filePath2)}>{row.fileName2}</MDButton>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDButton onClick={() => handleDownload(row.fileName3, row.filePath3)}>{row.fileName3}</MDButton>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDButton onClick={() => handleDownload(row.fileName4, row.filePath4)}>{row.fileName4}</MDButton>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDButton onClick={() => handleDownload(row.fileName5, row.filePath5)}>{row.fileName5}</MDButton>
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row">{row.status}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.approvedByName}</TableCell>
                                                                    <TableCell component="th" scope="row">{moment(row.approvedDate).format('DD/MM/YYYY')}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.approveRemarks}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".statusId"]}</div>
                                                </TableCell>
                                            </TableRow>                                           
                                        </TableBody>
                                    </Table>
                                </TableContainer>                                
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default ApprovedRejectedEstimates;