/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
 import Analytics from "layouts/dashboards/analytics";
// import Sales from "layouts/dashboards/sales";
// import ProfileOverview from "layouts/pages/profile/profile-overview";
// import AllProjects from "layouts/pages/profile/all-projects";
// import NewUser from "layouts/pages/users/new-user";
// import Settings from "layouts/pages/account/settings";
// import Billing from "layouts/pages/account/billing";
// import Invoice from "layouts/pages/account/invoice";
// import Timeline from "layouts/pages/projects/timeline";
// import PricingPage from "layouts/pages/pricing-page";
// import Widgets from "layouts/pages/widgets";
// import RTL from "layouts/pages/rtl";
// import Charts from "layouts/pages/charts";
// import Notifications from "layouts/pages/notifications";
// import Kanban from "layouts/applications/kanban";
// import Wizard from "layouts/applications/wizard";
// import DataTables from "layouts/applications/data-tables";
// import Calendar from "layouts/applications/calendar";

// import ProductPage from "layouts/ecommerce/products/product-page";

import SignInBasic from "layouts/authentication/sign-in/basic";
// import SignInCover from "layouts/authentication/sign-in/cover";
// import SignInIllustration from "layouts/authentication/sign-in/illustration";
// import SignUpCover from "layouts/authentication/sign-up/cover";
// import ResetCover from "layouts/authentication/reset-password/cover";

// import Bank from "layouts/masters/bank";
// import Branch from "layouts/masters/branch";
// import Bank from "layouts/masters/bank";
// import Country from "layouts/masters/country";
// import State from "layouts/masters/state";
// import District from "layouts/masters/district";
import User from "layouts/masters/user";
import CE from "layouts/masters/ce";
import SE from "layouts/masters/se";
import MechanicalDivision from "layouts/masters/mechanicaldivision";
import MechanicalSubDivision from "layouts/masters/mechanicalsubdivision";
import CivilCEO from "layouts/masters/civilceo";
import CivilSEO from "layouts/masters/civilseo";
import CivilDivision from "layouts/masters/civildivision";
import CivilSubDivision from "layouts/masters/civilsubdivision";
import Designation from "layouts/masters/designation";
import WorkStageRemarks from "layouts/masters/workstageremark";
import Projects from "layouts/masters/project";
import GateTypes from "layouts/masters/gatetype";
import HoistTypes from "layouts/masters/hoisttype";
import GateComponents from "layouts/masters/gatecomponent";
import HoistComponents from  "layouts/masters/hoistcomponent";
import ProjectGates from "layouts/masters/projectgate";
import ProjectHoists from "layouts/masters/projecthoist";
import WorkDetails from "layouts/work/workdetails";
import WorkAllocationToSE from "layouts/work/workallocations/workallocationtose";
import WorkAllocationToMechanicalDivision from "layouts/work/workallocations/workallocationtomechdiv";
import WorkAllocationToMechanicalSubDivision from "layouts/work/workallocations/workallocationtomechsubdiv";
import WorkTask from "layouts/work/worktasks/worktask";
import WorkSubTask from "layouts/work/worktasks/worksubtask";
import WorkSubSubTask from "layouts/work/worktasks/worksubsubtask";
import WorkStatus from "layouts/work/workstatus";
import EstimateForApproval from "layouts/work/estimateforapproval";
import ApprovedRejectedEstimates from "layouts/work/estimateforapproval/approvedrejectedestimates";
import Reports from "layouts/reports";

// Material Dashboard 2 PRO React components
// import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
// import profilePicture from "assets/images/team-3.jpg";

const routes = [
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: "/dashboards/analytics",    
    component: <Analytics />,      
    noCollapse: true
  },  
  { type: "divider", key: "divider-1" },
  {
    type: "collapse",
    name: "Work Management",
    key: "work",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [  
      {
        name: "Work Details",
        key: "workdetails",
        route: "/work/workdetails",
        component: <WorkDetails />,
      },  
      {
        name: "Work Allocation-SE",
        key: "workallocationtose",
        route: "/work/workallocationtose",
        component: <WorkAllocationToSE />,
      }, 
      {
        name: "Work Allocation-Division",
        key: "workallocationtomechdiv",
        route: "/work/workallocationtomechdiv",
        component: <WorkAllocationToMechanicalDivision />,
      },  
      {
        name: "Work Allocation-Sub Division",
        key: "workallocationtomechsubdiv",
        route: "/work/workallocationtomechsubdiv",
        component: <WorkAllocationToMechanicalSubDivision />,
      },
      {
        name: "Work Task",
        key: "worktask",
        route: "/work/worktask",
        component: <WorkTask />,
      }, 
      {
        name: "Work Sub Task",
        key: "worksubtask",
        route: "/work/worksubtask",
        component: <WorkSubTask />,
      }, 
      {
        name: "Work Sub Sub Task",
        key: "worksubsubtask",
        route: "/work/worksubsubtask",
        component: <WorkSubSubTask />,
      }, 
      {
        name: "Work Status",
        key: "workstatus",
        route: "/work/workstatus",
        component: <WorkStatus />,
      }, 
    ],
  },
  { type: "divider", key: "divider-2" },
  { 
    type: "collapse",
    name: "Estimates For Approval",
    key: "estimatesforapproval",
    icon: <Icon fontSize="medium">equalizer</Icon>,  
    route: "/estimatesforapproval",
    component: <EstimateForApproval />,  
    noCollapse: true,         
  },
  { type: "divider", key: "divider-6" },
  { 
    type: "collapse",
    name: "Estimates Status",    
    key: "approvedrejectedestimates",
    icon: <Icon fontSize="medium">equalizer</Icon>,  
    route: "/approvedrejectedestimates",
    component: <ApprovedRejectedEstimates />,  
    noCollapse: true,         
  },
  { type: "divider", key: "divider-3" },
  { 
    type: "collapse",
    name: "Reports",
    key: "reports",
    icon: <Icon fontSize="medium">equalizer</Icon>,  
    route: "/donor/reports",
    component: <Reports />,  
    noCollapse: true,         
  },
  { type: "divider", key: "divider-4" },
  {
    type: "collapse",
    name: "Settings",
    key: "masters",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [  
      {
        name: "CE",
        key: "ce",
        route: "/masters/ce",
        component: <CE />,
      },  
      {
        name: "SE",
        key: "se",
        route: "/masters/se",
        component: <SE />,
      },
      {
        name: "Mechanical Division",
        key: "mechanicaldivision",
        route: "/masters/mechanicaldivision",
        component: <MechanicalDivision />,
      },
      {
        name: "Mechanical Sub Division",
        key: "mechanicalsubdivision",
        route: "/masters/mechanicalsubdivision",
        component: <MechanicalSubDivision />,
      },
      {
        name: "Civil CEO",
        key: "ceo",
        route: "/masters/ceo",
        component: <CivilCEO />,
      },      
      {
        name: "Civil SEO",
        key: "seo",
        route: "/masters/seo",
        component: <CivilSEO />,
      }, 
      {
        name: "Civil Division",
        key: "civildivision",
        route: "/masters/civildivision",
        component: <CivilDivision />,
      },
      {
        name: "Civil Sub Division",
        key: "civilsubdivision",
        route: "/masters/civilsubdivision",
        component: <CivilSubDivision />,
      },
      // {
      //   name: "Bank",
      //   key: "bank",
      //   route: "/masters/bank",
      //   component: <Bank />,
      // },  
      // {
      //   name: "Country",
      //   key: "country",
      //   route: "/masters/country",
      //   component: <Country />,
      // },
      // {
      //   name: "State",
      //   key: "state",
      //   route: "/masters/state",
      //   component: <State />,
      // },
      // {
      //   name: "District",
      //   key: "district",
      //   route: "/masters/district",
      //   component: <District />,
      // },   
      {
        name: "Designation",
        key: "designation",
        route: "/masters/designation",
        component: <Designation />,
      },  
      {
        name: "Users",
        key: "user",
        route: "/masters/user",
        component: <User />,
      },
      {
        name: "Work Stage Remarks",
        key: "workstageremarks",
        route: "/masters/workstageremarks",
        component: <WorkStageRemarks />,
      },
      {
        name: "Projects",
        key: "projects",
        route: "/masters/projects",
        component: <Projects />,
      },
      {
        name: "Gate Types",
        key: "gatetypes",
        route: "/masters/gatetypes",
        component: <GateTypes />,
      },
      {
        name: "Hoist Types",
        key: "hoisttypes",
        route: "/masters/hoisttypes",
        component: <HoistTypes />,
      },
      {
        name: "Gate Components",
        key: "gatecomponents",
        route: "/masters/gatecomponents",
        component: <GateComponents />,
      },
      {
        name: "Hoist Components",
        key: "hoistcomponents",
        route: "/masters/hoistcomponents",
        component: <HoistComponents />,
      },
      {
        name: "Project Gates",
        key: "projectgates",
        route: "/masters/projectgates",
        component: <ProjectGates />,
      },
      {
        name: "Project Hoists",
        key: "projecthoists",
        route: "/masters/projecthoists",
        component: <ProjectHoists />,
      },
    ],
  },
  { type: "divider", key: "divider-5" },  
  // {
  //   type: "collapse",
  //   name: "Change Password",
  //   key: "changepassword",
  //   icon: <Icon fontSize="medium">password</Icon>,
  //   route: "/authentication/sign-in/basic",
  //   component: <SignInBasic />,         
  //   noCollapse: true
  // },
  {
    type: "collapse",
    name: "Logout",
    key: "logout",
    icon: <Icon fontSize="medium">logout</Icon>,
    route: "/authentication/sign-in/basic",
    component: <SignInBasic />,         
    noCollapse: true
  },    
];

export default routes;
