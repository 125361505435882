// import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import Select from 'react-select';
import * as moment from 'moment';
import { Radio } from '@mui/material';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import * as service from '../../../services/service';

function EstimateForApproval() {
    const [state, setState] = useState({ remark: '' });
    const [workStatusData, setWorkStatusData] = useState([]);
    const [statusId, setStatusId] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState('');
    const userLvl = JSON.parse(sessionStorage.getItem('userData')).userLevel;
    // const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const getWorkStatusDetails = () => {
        const errs = {};
        fetch(
            service.GET_PENDING_WORK_STATUS_BY_USERID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    AddedBy: JSON.parse(sessionStorage.getItem('userData')).userId
                })
            })
            .then(res => res.json())
            .then(result => {
                setWorkStatusData(result);
            })
            .catch(err => {
                if (err) {
                    errs[".invalid"] = err;
                    setErrors(err);
                };
            });
    };

    useEffect(() => {
        setLoading(true);
        getWorkStatusDetails();
        setLoading(false);
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!statusId) {
            isValid = false;
            errs[".statusId"] = "Please select work";
        }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const handleRadioChange = (stId, amt) => {
        setStatusId(stId);
        setAmount(amt);
    }

    const clearHandler = () => {
        localStorage.setItem('ID', null);
        setState({ ...state, remark: '' });
        setStatusId('');
    }

    const handleDownload = (fileName, filePath) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("FileName", fileName);
        formData.append("FilePath", filePath);
        fetch(
            service.DOWNLOAD_FILE,
            {
                method: 'POST',
                body: formData
            })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    fileName,
                );
                // Append to html link element page
                document.body.appendChild(link);
                // Start download
                link.click();
                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });

        setLoading(false);
    };

    const submitHandler = (e, st) => {
        e.preventDefault();
        setLoading(true);
        if (validate()) {
            let stat = '';
            stat = st === 'Approved' ? 'approve' : 'reject';

            // eslint-disable-next-line
            if (window.confirm(`Do you really want to ${stat} the estimate?`)) {
                fetch(
                    service.UPDATE_WORK_STATUS,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            StatusId: statusId,
                            Status: st,
                            ApproveRemarks: state.remark,
                            ApprovedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                            AddedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                            UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).userId
                        })
                    })
                    .then(resp => resp.json())
                    .then(respo => {
                        if (respo.messageType === 'Success') {
                            // eslint-disable-next-line
                            alert(respo.message);
                            setState({ ...state, remark: '' });
                            getWorkStatusDetails();
                        }
                        else if (respo.messageType === 'Failed') {
                            // eslint-disable-next-line
                            alert(respo.message);
                        }
                        else {
                            // eslint-disable-next-line
                            alert(respo.message);
                        }
                    });
            }
        }
        setLoading(false);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Estimations For Approval
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center', }}>
                                    <Table aria-label="simple table">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan="2" align="center">
                                                    <Table aria-label="simple table">
                                                        <TableRow style={{ backgroundColor: 'lightgrey' }}>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Select</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Division</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Sub Division</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Project</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Gate Type</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Gate Number</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Hoist Type</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Hoist Number</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Work Description</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Work Date</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Remarks</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Task</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Sub Task</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Sub Sub Task</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Stage</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Stage Remarks</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Stage Date</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Remarks</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Amount</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>File1 </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>File2</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>File3</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>File4</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>File5</TableCell>
                                                            <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                                                        </TableRow>
                                                        <TableBody>
                                                            {workStatusData.map((row) => (
                                                                <TableRow
                                                                    key={row.workId}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell>
                                                                        <Radio onChange={() => handleRadioChange(row.statusId, row.amount)}
                                                                            value={row.statusId} name="radio-buttons"
                                                                            checked={statusId === row.statusId} />

                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row">{row.mechanicalDivision}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.mechanicalSubDivision}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.project}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.gateType}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.gateNumber}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.hoistType}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.hoistNumber}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.workDescription}</TableCell>
                                                                    <TableCell component="th" scope="row">{moment(row.workDate).format('DD/MM/YYYY')}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.remarks}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.task}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.subTask}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.subSubTask}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.workStage}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.workStageRemarks}</TableCell>
                                                                    <TableCell component="th" scope="row">{moment(row.statusDate).format('DD/MM/YYYY')}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.remarks}</TableCell>
                                                                    <TableCell component="th" scope="row">{row.amount}</TableCell>
                                                                    <TableCell>
                                                                        <MDButton onClick={() => handleDownload(row.fileName1, row.filePath1)}>{row.fileName1}</MDButton>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDButton onClick={() => handleDownload(row.fileName2, row.filePath2)}>{row.fileName2}</MDButton>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDButton onClick={() => handleDownload(row.fileName3, row.filePath3)}>{row.fileName3}</MDButton>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDButton onClick={() => handleDownload(row.fileName4, row.filePath4)}>{row.fileName4}</MDButton>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <MDButton onClick={() => handleDownload(row.fileName5, row.filePath5)}>{row.fileName5}</MDButton>
                                                                    </TableCell>
                                                                    <TableCell component="th" scope="row">{row.status}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".statusId"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <br />
                                            <TableRow>
                                                <TableCell width="25%">
                                                    <MDInput label="Remarks" fullWidth value={state.remark || ''}
                                                        name="remark"
                                                        onChange={changeHandler}
                                                        id="remark"
                                                        required
                                                        multiline />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".remark"]}</div>
                                                </TableCell>
                                                <TableCell />
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <div>
                                            {(userLvl === 'Mechanical Division' && amount <= 500000) ?
                                                <div>
                                                    <MDButton color="info" onClick={e => submitHandler(e, 'Approved')}>
                                                        APPROVE
                                                    </MDButton>&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <MDButton color="info" onClick={e => submitHandler(e, 'Rejected')}>
                                                        REJECT
                                                    </MDButton>&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <MDButton color="info" onClick={clearHandler}>
                                                        CLEAR
                                                    </MDButton>
                                                </div>
                                                : null}
                                            {(userLvl === 'SE' && amount > 500000 && amount <= 2500000) ?
                                                <div>
                                                    <MDButton color="info" onClick={e => submitHandler(e, 'Approved')}>
                                                        APPROVE
                                                    </MDButton>&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <MDButton color="info" onClick={e => submitHandler(e, 'Rejected')}>
                                                        REJECT
                                                    </MDButton>&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <MDButton color="info" onClick={clearHandler}>
                                                        CLEAR
                                                    </MDButton>
                                                </div>
                                                : null}
                                            {(userLvl === 'CE' && amount > 2500000) ?
                                                <div>
                                                    <MDButton color="info" onClick={e => submitHandler(e, 'Approved')}>
                                                        APPROVE
                                                    </MDButton>&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <MDButton color="info" onClick={e => submitHandler(e, 'Rejected')}>
                                                        REJECT
                                                    </MDButton>&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <MDButton color="info" onClick={clearHandler}>
                                                        CLEAR
                                                    </MDButton>
                                                </div>
                                                : null}
                                        </div>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default EstimateForApproval;