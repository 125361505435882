
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
// import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { Circles } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as moment from 'moment';
import { Radio } from '@mui/material';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
// import { jsPDF } from 'jspdf';
// import html2canvas from "html2canvas";
import Select from 'react-select';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { styled } from '@mui/material/styles';
import * as service from '../../services/service';

function Reports() {
    const [workStatusData, setWorkStatusData] = useState([]);
    const [showWorkStausReport, setShowWorkStatusReport] = useState(false);
    const [reportType, setReportType] = useState('');
    const [CEData, setCEData] = useState([]);
    const [SEData, setSEData] = useState([]);
    const [MechanicalDivisionData, setMechanicalDivisionData] = useState([]);
    const [MechanicalSubDivisionData, setMechanicalSubDivisionData] = useState([]);
    const [CEId, setCEId] = useState('');
    const [SEId, setSEId] = useState('');
    const [MechanicalDivisionId, setMechanicalDivisionId] = useState('');
    const [MechanicalSubDivisionId, setMechanicalSubDivisionId] = useState('');
    const [CEOData, setCEOData] = useState([]);
    const [SEOData, setSEOData] = useState([]);
    const [CivilDivisionData, setCivilDivisionData] = useState([]);
    const [CivilSubDivisionData, setCivilSubDivisionData] = useState([]);
    const [ProjectData, setProjectData] = useState([]);
    const [CEOId, setCEOId] = useState('');
    const [SEOId, setSEOId] = useState('');
    const [CivilDivisionId, setCivilDivisionId] = useState('');
    const [CivilSubDivisionId, setCivilSubDivisionId] = useState('');
    const [ProjectId, setProjectId] = useState('');
    const [AllocationData, setAllocationData] = useState([]);
    const [WorkId, setWorkId] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    // const [fromDate, setFromDate] = useState(null);
    // const [toDate, setToDate] = useState(null);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const userLvl = JSON.parse(sessionStorage.getItem('userData')).userLevel;
    const getCEs = () => {
        fetch(
            service.CE_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.ceId, label: item.ce }));
                setCEData(data2);
            });
    };

    const getMechanicalSubDivisions = (id) => {
        fetch(
            service.GET_MECHANICAL_SUB_DIVISION_BY_MECH_DIV_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    MechanicalDivisionId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.mechanicalSubDivisionId, label: item.mechanicalSubDivision }));
                const all = ({ value: "0", label: "All" });
                data2.unshift(all);
                setMechanicalSubDivisionId(all);
                setMechanicalSubDivisionData(data2);
                if (JSON.parse(sessionStorage.getItem('userData')).mechanicalSubDivisionId > 0) {
                    const sb = ({ label: JSON.parse(sessionStorage.getItem('userData')).mechanicalSubDivision, value: JSON.parse(sessionStorage.getItem('userData')).mechanicalSubDivisionId });
                    setMechanicalSubDivisionId(sb);
                }
            });
    };

    const getMechanicalDivisions = (id) => {
        fetch(
            service.GET_MECHANICAL_DIVISION_BY_SE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SEId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.mechanicalDivisionId, label: item.mechanicalDivision }));
                const all = ({ value: "0", label: "All" });
                data2.unshift(all);
                setMechanicalDivisionData(data2);
                setMechanicalDivisionId(all);
                if (JSON.parse(sessionStorage.getItem('userData')).mechanicalDivisionId > 0) {
                    const dv = ({ label: JSON.parse(sessionStorage.getItem('userData')).mechanicalDivision, value: JSON.parse(sessionStorage.getItem('userData')).mechanicalDivisionId });
                    setMechanicalDivisionId(dv);
                    getMechanicalSubDivisions(dv.value);
                }
            });
    };

    const getSEs = (id) => {
        fetch(
            service.GET_SE_BY_CE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CEId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.seId, label: item.se }));
                const all = ({ value: "0", label: "All" });
                data2.unshift(all);
                setSEData(data2);
                setSEId(all);
                if (JSON.parse(sessionStorage.getItem('userData')).seId > 0) {
                    const sc = ({ label: JSON.parse(sessionStorage.getItem('userData')).se, value: JSON.parse(sessionStorage.getItem('userData')).seId });
                    setSEId(sc);
                    getMechanicalDivisions(JSON.parse(sessionStorage.getItem('userData')).seId);
                }
            });
    };

    const getCEOs = () => {
        fetch(
            service.CIVIL_CEO_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.civilCEOId, label: item.civilCEO }));
                setCEOData(data2);
            });
    };

    const getSEOs = (id) => {
        fetch(
            service.GET_CIVIL_SEO_BY_CEO_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CivilCEOId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.civilSEOId, label: item.civilSEO }));
                setSEOData(data2);
            });
    };

    const getCivilDivisions = (id) => {
        fetch(
            service.GET_CIVIL_DIVISION_BY_SEO_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CivilSEOId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.civilDivisionId, label: item.civilDivision }));
                setCivilDivisionData(data2);
            });
    };

    const getCivilSubDivisions = (id) => {
        fetch(
            service.GET_CIVIL_SUB_DIVISION_BY_CIVIL_DIV_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CivilDivisionId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.civilSubDivisionId, label: item.civilSubDivision }));
                setCivilSubDivisionData(data2);
            });
    };

    const getProjects = (ceoId, seoId, divId, subId) => {
        fetch(
            service.PROJECT_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CivilCEOId: ceoId,
                    CivilSEOId: seoId,
                    CivilDivisionId: divId,
                    CivilSubDivisionId: subId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.projectId, label: item.project }));
                setProjectData(data2);
            });
    };

    const getAllocationDetails = (id) => {
        const errs = {};
        fetch(
            service.GET_WORK_ALLOCATION_TO_MECHSUBDIV_BY_SUBDIVID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProjectId: id,
                    MechanicalSubDivisionId: MechanicalDivisionId.value,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.workId, label: item.workDescription }));
                setAllocationData(data2);
            })
            .catch(err => {
                if (err) {
                    errs[".invalid"] = err;
                    setErrors(err);
                };
            });
    };

    useEffect(() => {
        setLoading(true);
        getCEOs();
        getCEs();
        const cc = ({ label: JSON.parse(sessionStorage.getItem('userData')).ce, value: JSON.parse(sessionStorage.getItem('userData')).ceId });
        setCEId(cc);
        getSEs(cc.value);
        setLoading(false);
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!reportType) {
            isValid = false;
            errs[".reportType"] = "Please select report type";
        }
        if (!CEOId) {
            isValid = false;
            errs[".CEOId"] = "Please select CEO";
        }
        if (!SEOId) {
            isValid = false;
            errs[".SEOId"] = "Please select SEO";
        }
        if (!CivilDivisionId) {
            isValid = false;
            errs[".CivilDivisionId"] = "Please select Civil Division";
        }
        if (!CivilSubDivisionId) {
            isValid = false;
            errs[".CivilSubDivisionId"] = "Please select Civil Sub Division";
        }
        if (!ProjectId) {
            isValid = false;
            errs[".ProjectId"] = "Please select Project";
        }
        if (!SEId) {
            isValid = false;
            errs[".seId"] = "Please select SE";
        }
        if (!MechanicalDivisionId) {
            isValid = false;
            errs[".MechanicalDivisionId"] = "Please select Mechanical Division";
        }
        if (!MechanicalSubDivisionId) {
            isValid = false;
            errs[".MechanicalSubDivisionId"] = "Please select Mechanical Sub Division";
        }
        if (!WorkId) {
            isValid = false;
            errs[".WorkId"] = "Please select work";
        }
        setErrors(errs);
        return isValid;
    }

    const handleChange = (event) => {
        setShowWorkStatusReport(false);
        setReportType(event.target.value);
    };

    const CEChangeHandler = (ev) => {
        setCEId(ev);
        getSEs(ev.value);
    }
    const SEChangeHandler = (ev) => {
        setSEId(ev);
        setMechanicalDivisionData([]);
        getMechanicalDivisions(ev.value);
    }
    const MechanicalDivisionChangeHandler = (ev) => {
        setMechanicalDivisionId(ev);
        setMechanicalSubDivisionData([]);
        getMechanicalSubDivisions(ev.value);
    }
    const MechanicalSubDivisionChangeHandler = (ev) => {
        setMechanicalSubDivisionId(ev);
    }
    const CEOChangeHandler = (ev) => {
        setCEOId(ev);
        getSEOs(ev.value);
        getProjects(ev.value, 0, 0, 0);
    }
    const SEOChangeHandler = (ev) => {
        setSEOId(ev);
        setCivilDivisionData([]);
        getCivilDivisions(ev.value);
        getProjects(CEOId.value, ev.value, 0, 0);
    }
    const CivilDivisionChangeHandler = (ev) => {
        setCivilDivisionId(ev);
        setCivilSubDivisionData([]);
        getCivilSubDivisions(ev.value);
        getProjects(CEOId.value, SEOId.value, ev.value, 0);
    }
    const CivilSubDivisionChangeHandler = (ev) => {
        setCivilSubDivisionId(ev);
        getProjects(CEOId.value, SEOId.value, CivilDivisionId.value, ev.value);
    }
    const ProjectChangeHandler = (ev) => {
        setProjectId(ev);
        getAllocationDetails(ev.value);
    }
    const WorkChangeHandler = (ev) => {
        setWorkId(ev);
    }

    const GetWorkStatusReport = () => {
        const errs = {};
        fetch(
            service.GET_WORK_STATUS,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProjectId: ProjectId.value,
                    WorkId: WorkId.value
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.length === 0) {
                    errs[".invalid"] = 'No Data Found!';
                    setErrors(errs);
                    setShowWorkStatusReport(false);
                }
                else if (result.length > 0 && result[0].message.messageType === 'Success') {
                    setShowWorkStatusReport(true);
                    setWorkStatusData(result);
                    errs[".invalid"] = '';
                    setErrors(errs);
                }
                else if (result[0].message.messageType === 'Failed') {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowWorkStatusReport(false);
                }
                else {
                    errs[".invalid"] = result[0].message.message;
                    setErrors(errs);
                    setShowWorkStatusReport(false);
                }
            });
    };

    const submitHandler = e => {
        setShowWorkStatusReport(false);
        e.preventDefault();
        setLoading(true);
        if (validate()) {
            setLoading(true);
            if (reportType === "workStatusReport") {
                GetWorkStatusReport();
            }
            setLoading(false);
        }
        setLoading(false);
    }
    const handleDownload = (fileName, filePath) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("FileName", fileName);
        formData.append("FilePath", filePath);
        fetch(
            service.DOWNLOAD_FILE,
            {
                method: 'POST',
                body: formData
            })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    fileName,
                );
                // Append to html link element page
                document.body.appendChild(link);
                // Start download
                link.click();
                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });

        setLoading(false);
    };
    const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow:
            theme.palette.mode === 'dark'
                ? '0 0 0 1px rgb(16 22 26 / 40%)'
                : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
        backgroundImage:
            theme.palette.mode === 'dark'
                ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
                : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background:
                theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
        },
    }));
    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    });

    const ShowWorkStatusReport = React.memo(() => (
        <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}>
            <Table id="dvWorkStatusReport">
                <TableRow style={{ backgroundColor: 'lightgrey' }}>
                    <TableCell style={{ fontWeight: 'bold' }}>Task</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Sub Task</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Sub Sub Task</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Stage</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Stage Remarks</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Stage Date</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Remarks</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Amount</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>File1 </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>File2</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>File3</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>File4</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>File5</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Approve/Reject Remarks</TableCell>
                </TableRow>
                <TableBody>
                    {workStatusData.map((row) => (
                        <TableRow
                            key={row.statusId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{row.task}</TableCell>
                            <TableCell component="th" scope="row">{row.subTask}</TableCell>
                            <TableCell component="th" scope="row">{row.subSubTask}</TableCell>
                            <TableCell component="th" scope="row">{row.workStage}</TableCell>
                            <TableCell component="th" scope="row">{row.workStageRemarks}</TableCell>
                            <TableCell component="th" scope="row">{moment(row.statusDate).format('DD/MM/YYYY')}</TableCell>
                            <TableCell component="th" scope="row">{row.remarks}</TableCell>
                            <TableCell component="th" scope="row">{row.amount}</TableCell>
                            <TableCell>
                                <MDButton onClick={() => handleDownload(row.fileName1, row.filePath1)}>{row.fileName1}</MDButton>
                            </TableCell>
                            <TableCell>
                                <MDButton onClick={() => handleDownload(row.fileName2, row.filePath2)}>{row.fileName2}</MDButton>
                            </TableCell>
                            <TableCell>
                                <MDButton onClick={() => handleDownload(row.fileName3, row.filePath3)}>{row.fileName3}</MDButton>
                            </TableCell>
                            <TableCell>
                                <MDButton onClick={() => handleDownload(row.fileName4, row.filePath4)}>{row.fileName4}</MDButton>
                            </TableCell>
                            <TableCell>
                                <MDButton onClick={() => handleDownload(row.fileName5, row.filePath5)}>{row.fileName5}</MDButton>
                            </TableCell>
                            <TableCell component="th" scope="row">{row.status}</TableCell>
                            <TableCell component="th" scope="row">{row.approveRemarks}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

    ));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Reports
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                <TableContainer component={Paper} style={{ width: '98%', alignSelf: 'center' }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{ fontSize: '14px', fontWeight: 'bold', width: '25%' }}>
                                                    Work Status Report
                                                    <Radio
                                                        checked={reportType === 'workStatusReport'}
                                                        onChange={handleChange}
                                                        value="workStatusReport"
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                        title="Work Status Report"
                                                        sx={{
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        }}
                                                        disableRipple
                                                        color="default"
                                                        checkedIcon={<BpCheckedIcon />}
                                                        icon={<BpIcon />}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ fontSize: '14px', fontWeight: 'bold', width: '25%' }}>
                                                    Work Progress Report
                                                    <Radio
                                                        checked={reportType === 'workProgressReport'}
                                                        onChange={handleChange}
                                                        value="workProgressReport"
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                        title="Work Status Report"
                                                        sx={{
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        }}
                                                        disableRipple
                                                        color="default"
                                                        checkedIcon={<BpCheckedIcon />}
                                                        icon={<BpIcon />}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontSize: '14px', fontWeight: 'bold', width: '25%' }}>
                                                    Work History Report
                                                    <Radio
                                                        checked={reportType === 'workHistoryReport'}
                                                        onChange={handleChange}
                                                        value="workHistoryReport"
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                        title="Work Status Report"
                                                        sx={{
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        }}
                                                        disableRipple
                                                        color="default"
                                                        checkedIcon={<BpCheckedIcon />}
                                                        icon={<BpIcon />}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ fontSize: '14px', fontWeight: 'bold', width: '25%' }}>
                                                    Estimation Details Report
                                                    <Radio
                                                        checked={reportType === 'estimationDetailsReport'}
                                                        onChange={handleChange}
                                                        value="estimationDetailsReport"
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                        title="Work Status Report"
                                                        sx={{
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        }}
                                                        disableRipple
                                                        color="default"
                                                        checkedIcon={<BpCheckedIcon />}
                                                        icon={<BpIcon />}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".reportType"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Select
                                                        name="CE"
                                                        options={CEData} placeholder="Select CE"
                                                        value={CEId}
                                                        onChange={CEChangeHandler}
                                                        isSearchable
                                                        styles={selectStyles}
                                                        isDisabled
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".ceId"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <Select
                                                        name="SE"
                                                        options={SEData} placeholder="Select SE"
                                                        value={SEId}
                                                        onChange={SEChangeHandler}
                                                        isSearchable
                                                        styles={selectStyles}
                                                        isDisabled={userLvl === 'SE' || userLvl === 'Mechanical Division' || userLvl === 'Mechanical Sub Division'}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".seId"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Select
                                                        name="Mechanical Division"
                                                        options={MechanicalDivisionData} placeholder="Select Mechanical Division"
                                                        value={MechanicalDivisionId}
                                                        onChange={MechanicalDivisionChangeHandler}
                                                        isSearchable
                                                        styles={selectStyles}
                                                        isDisabled={userLvl === 'Mechanical Division' || userLvl === 'Mechanical Sub Division'}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".MechanicalDivisionId"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <Select
                                                        name="Mechanical Sub Division"
                                                        options={MechanicalSubDivisionData} placeholder="Select Mechanical Sub Division"
                                                        value={MechanicalSubDivisionId}
                                                        onChange={MechanicalSubDivisionChangeHandler}
                                                        isSearchable
                                                        styles={selectStyles}
                                                        isDisabled={userLvl === 'Mechanical Sub Division'}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".MechanicalSubDivisionId"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell width="50%">
                                                    <Select
                                                        name="Civil CEO"
                                                        options={CEOData} placeholder="Select CEO"
                                                        value={CEOId}
                                                        onChange={CEOChangeHandler}
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".CEOId"]}</div>
                                                </TableCell>
                                                <TableCell width="50%">
                                                    <Select
                                                        name="Civil SEO"
                                                        options={SEOData} placeholder="Select SEO"
                                                        value={SEOId}
                                                        onChange={SEOChangeHandler}
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".SEOId"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Select
                                                        name="Civil Division"
                                                        options={CivilDivisionData} placeholder="Select Civil Division"
                                                        value={CivilDivisionId}
                                                        onChange={CivilDivisionChangeHandler}
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".CivilDivisionId"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <Select
                                                        name="Civil Sub Division"
                                                        options={CivilSubDivisionData} placeholder="Select Civil Sub Division"
                                                        value={CivilSubDivisionId}
                                                        onChange={CivilSubDivisionChangeHandler}
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".CivilSubDivisionId"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Select
                                                        name="Project"
                                                        options={ProjectData} placeholder="Select Project"
                                                        value={ProjectId}
                                                        onChange={ProjectChangeHandler}
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".ProjectId"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <Select
                                                        name="Work"
                                                        options={AllocationData} placeholder="Select Work"
                                                        value={WorkId}
                                                        onChange={WorkChangeHandler}
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".WorkId"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            {/* <TableRow>
                                                <TableCell>
                                                    From Date
                                                    <DatePicker
                                                        id="fromDate"
                                                        selected={fromDate}
                                                        onChange={date => setFromDate(date)}
                                                        dateFormat="MM/dd/yyyy"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    To Date
                                                    <DatePicker
                                                        id="toDate"
                                                        selected={toDate}
                                                        onChange={date => setToDate(date)}
                                                        dateFormat="MM/dd/yyyy"
                                                    />
                                                </TableCell>
                                            </TableRow> */}
                                            <TableRow>
                                                <TableCell colSpan={4} align="center">
                                                    <MDBox mt={4} mb={1} textAlign="center">
                                                        {loading ?
                                                            <Circles
                                                                heigth="20"
                                                                width="20"
                                                                color='grey'
                                                                ariaLabel='loading'
                                                            />

                                                            :
                                                            <MDButton color="info" onClick={submitHandler}>
                                                                Show
                                                            </MDButton>
                                                        }
                                                        {errors ? <div style={{ fontSize: '14px', color: 'red', fontWeight: 'bold' }}>{errors[".invalid"]}</div> : null}
                                                    </MDBox>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TableContainer component={Paper} style={{ width: '98%', alignSelf: 'center' }}>
                                    <Table>
                                        <TableRow>
                                            <TableCell>
                                                {showWorkStausReport ? <ShowWorkStatusReport /> : null}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center">
                                                {showWorkStausReport ?
                                                    <ReactHTMLTableToExcel table="dvWorkStatusReport" className="info"
                                                        filename="ReportData" sheet="Sheet" buttonText="EXPORT TO EXCEL"
                                                    />
                                                    : null}
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Reports;
