// import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from 'react-select';
import * as moment from 'moment';
import { Radio, TextField } from '@mui/material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as service from '../../../services/service';

function WorkStatus() {
    const [state, setState] = useState({ remark: '', amount: '', worktargetcompleted: '' });
    const [CEData, setCEData] = useState([]);
    const [SEData, setSEData] = useState([]);
    const [MechanicalDivisionData, setMechanicalDivisionData] = useState([]);
    const [MechanicalSubDivisionData, setMechanicalSubDivisionData] = useState([]);
    const [CEId, setCEId] = useState('');
    const [SEId, setSEId] = useState('');
    const [MechanicalDivisionId, setMechanicalDivisionId] = useState('');
    const [MechanicalSubDivisionId, setMechanicalSubDivisionId] = useState('');
    const [CEOData, setCEOData] = useState([]);
    const [SEOData, setSEOData] = useState([]);
    const [CivilDivisionData, setCivilDivisionData] = useState([]);
    const [CivilSubDivisionData, setCivilSubDivisionData] = useState([]);
    const [ProjectData, setProjectData] = useState([]);
    const [CEOId, setCEOId] = useState('');
    const [SEOId, setSEOId] = useState('');
    const [CivilDivisionId, setCivilDivisionId] = useState('');
    const [CivilSubDivisionId, setCivilSubDivisionId] = useState('');
    const [ProjectId, setProjectId] = useState('');
    const [allocationData, setAllocationData] = useState([]);
    const [workId, setWorkId] = useState('');
    const [taskData, setTaskData] = useState([]);
    const [taskId, setTaskId] = useState('');
    const [subTaskData, setSubTaskData] = useState([]);
    const [subTaskId, setSubTaskId] = useState('');
    const [subSubTaskData, setSubSubTaskData] = useState([]);
    const [subSubTaskId, setSubSubTaskId] = useState('');
    const [stageData, setStageData] = useState([]);
    const [stageId, setStageId] = useState('');
    const [stageRemarkData, setStageRemarkData] = useState([]);
    const [stageRemarkId, setStageRemarkId] = useState('');
    const [statusDate, setStatusDate] = useState(new Date());
    const [file1, setFile1] = useState('');
    const [file2, setFile2] = useState('');
    const [file3, setFile3] = useState('');
    const [file4, setFile4] = useState('');
    const [file5, setFile5] = useState('');
    const [workStatusData, setWorkStatusData] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const getCEs = () => {
        fetch(
            service.CE_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.ceId, label: item.ce }));
                setCEData(data2);
            });
    };

    const getSEs = (id) => {
        fetch(
            service.GET_SE_BY_CE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CEId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.seId, label: item.se }));
                setSEData(data2);
            });
    };
    const getMechanicalDivisions = (id) => {
        fetch(
            service.GET_MECHANICAL_DIVISION_BY_SE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SEId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.mechanicalDivisionId, label: item.mechanicalDivision }));
                setMechanicalDivisionData(data2);
            });
    };

    const getMechanicalSubDivisions = (id) => {
        fetch(
            service.GET_MECHANICAL_SUB_DIVISION_BY_MECH_DIV_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    MechanicalDivisionId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.mechanicalSubDivisionId, label: item.mechanicalSubDivision }));
                setMechanicalSubDivisionData(data2);
            });
    };

    const getCEOs = () => {
        fetch(
            service.CIVIL_CEO_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.civilCEOId, label: item.civilCEO }));
                setCEOData(data2);
            });
    };

    const getSEOs = (id) => {
        fetch(
            service.GET_CIVIL_SEO_BY_CEO_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CivilCEOId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.civilSEOId, label: item.civilSEO }));
                setSEOData(data2);
            });
    };

    const getCivilDivisions = (id) => {
        fetch(
            service.GET_CIVIL_DIVISION_BY_SEO_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CivilSEOId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.civilDivisionId, label: item.civilDivision }));
                setCivilDivisionData(data2);
            });
    };

    const getCivilSubDivisions = (id) => {
        fetch(
            service.GET_CIVIL_SUB_DIVISION_BY_CIVIL_DIV_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CivilDivisionId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.civilSubDivisionId, label: item.civilSubDivision }));
                setCivilSubDivisionData(data2);
            });
    };

    const getProjects = (ceoId, seoId, divId, subId) => {
        fetch(
            service.PROJECT_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CivilCEOId: ceoId,
                    CivilSEOId: seoId,
                    CivilDivisionId: divId,
                    CivilSubDivisionId: subId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.projectId, label: item.project }));
                setProjectData(data2);
            });
    };

    const getAllocationDetails = (id) => {
        const errs = {};
        fetch(
            service.GET_WORK_ALLOCATION_TO_MECHSUBDIV_BY_SUBDIVID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProjectId: id,
                    MechanicalSubDivisionId: JSON.parse(sessionStorage.getItem('userData')).mechanicalSubDivisionId
                })
            })
            .then(res => res.json())
            .then(result => {
                setAllocationData(result);
            })
            .catch(err => {
                if (err) {
                    errs[".invalid"] = err;
                    setErrors(err);
                };
            });
    };

    const getTaskDetails = (id) => {
        const errs = {};
        fetch(
            service.GET_WORK_TASKS,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    WorkId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.taskId, label: item.task }));
                setTaskData(data2);
            })
            .catch(err => {
                if (err) {
                    errs[".invalid"] = err;
                    setErrors(err);
                };
            });
    };

    const getSubTaskDetails = (id) => {
        const errs = {};
        fetch(
            service.GET_WORK_SUB_TASKS,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    TaskId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.subTaskId, label: item.subTask }));
                setSubTaskData(data2);
            })
            .catch(err => {
                if (err) {
                    errs[".invalid"] = err;
                    setErrors(err);
                };
            });
    };

    const getSubSubTaskDetails = (id) => {
        const errs = {};
        fetch(
            service.GET_WORK_SUB_SUB_TASKS,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SubTaskId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.subSubTaskId, label: item.subSubTask }));
                setSubSubTaskData(data2);
            })
            .catch(err => {
                if (err) {
                    errs[".invalid"] = err;
                    setErrors(err);
                };
            });
    };

    const getWorkStages = () => {
        const errs = {};
        fetch(
            service.WORK_STAGE_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.workStageId, label: item.workStage }));
                setStageData(data2);
            })
            .catch(err => {
                if (err) {
                    errs[".invalid"] = err;
                    setErrors(err);
                };
            });
    };

    const getWorkStageRemarks = (id) => {
        const errs = {};
        fetch(
            service.GET_WORK_STAGE_REMARK_BY_STAGE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    WorkStageId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.remarkId, label: item.remarks }));
                setStageRemarkData(data2);
            })
            .catch(err => {
                if (err) {
                    errs[".invalid"] = err;
                    setErrors(err);
                };
            });
    };

    const getWorkStatusDetails = (prId, wrId) => {
        const errs = {};
        fetch(
            service.GET_WORK_STATUS,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProjectId: prId,
                    WorkId: wrId
                })
            })
            .then(res => res.json())
            .then(result => {
                setWorkStatusData(result);
            })
            .catch(err => {
                if (err) {
                    errs[".invalid"] = err;
                    setErrors(err);
                };
            });
    };

    // const getDetailsById = (id) => {
    //     setLoading(true);
    //     const errs = {};
    //     fetch(
    //         service.GET_WORK_STATUS_BY_ID,
    //         {
    //             method: 'POST',
    //             headers: {
    //                 'Accept': 'application/json',
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify({
    //                 StatusId: id,
    //             })
    //         })
    //         .then(res => res.json())
    //         .then(result => {
    //             if (result.message.messageType === 'Success') {
    //                 setState({ ...state, remark: result.remarks });
    //             }
    //             else {
    //                 errs[".invalid"] = result.message.message;
    //                 setErrors(errs);
    //             }

    //         });
    //     setLoading(false);
    // };

    useEffect(() => {
        setLoading(true);
        getWorkStages();
        getCEOs();
        getCEs();
        const cc = ({ label: JSON.parse(sessionStorage.getItem('userData')).ce, value: JSON.parse(sessionStorage.getItem('userData')).ceId });
        setCEId(cc);
        getSEs(cc.value);
        const sc = ({ label: JSON.parse(sessionStorage.getItem('userData')).se, value: JSON.parse(sessionStorage.getItem('userData')).seId });
        setSEId(sc);
        getMechanicalDivisions(sc.value);
        const dv = ({ label: JSON.parse(sessionStorage.getItem('userData')).mechanicalDivision, value: JSON.parse(sessionStorage.getItem('userData')).mechanicalDivisionId });
        setMechanicalDivisionId(dv);
        getMechanicalSubDivisions(dv.value);
        const sb = ({ label: JSON.parse(sessionStorage.getItem('userData')).mechanicalSubDivision, value: JSON.parse(sessionStorage.getItem('userData')).mechanicalSubDivisionId });
        setMechanicalSubDivisionId(sb);
        setLoading(false);
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!CEOId) {
            isValid = false;
            errs[".CEOId"] = "Please select CEO";
        }
        if (!SEOId) {
            isValid = false;
            errs[".SEOId"] = "Please select SEO";
        }
        if (!CivilDivisionId) {
            isValid = false;
            errs[".CivilDivisionId"] = "Please select Civil Division";
        }
        if (!CivilSubDivisionId) {
            isValid = false;
            errs[".CivilSubDivisionId"] = "Please select Civil Sub Division";
        }
        if (!ProjectId) {
            isValid = false;
            errs[".ProjectId"] = "Please select Project";
        }
        if (!SEId) {
            isValid = false;
            errs[".seId"] = "Please select SE";
        }
        if (!MechanicalDivisionId) {
            isValid = false;
            errs[".MechanicalDivisionId"] = "Please select Mechanical Division";
        }
        if (!MechanicalSubDivisionId) {
            isValid = false;
            errs[".MechanicalSubDivisionId"] = "Please select Mechanical Sub Division";
        }
        if (!workId) {
            isValid = false;
            errs[".workIds"] = "Please select work";
        }
        if (!stageId) {
            isValid = false;
            errs[".stageId"] = "Please select Stage";
        }
        if (!stageRemarkId) {
            isValid = false;
            errs[".stageRemarkId"] = "Please select Stage Remark";
        }
        if (!statusDate) {
            isValid = false;
            errs[".statusDate"] = "Please enter Status Date";
        }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });

        if (event.target.name === 'amount') {
            if (Number.isNaN(parseFloat(event.target.value))) {
                // eslint-disable-next-line
                alert("Please enter numbers only");
                event.target.value = ""; // eslint-disable-line no-param-reassign
            }
        }

        if (event.target.name === 'worktargetcompleted') {
            if (Number.isNaN(parseFloat(event.target.value))) {
                // eslint-disable-next-line
                alert("Please enter numbers only");
                event.target.value = ""; // eslint-disable-line no-param-reassign
            }
        }
    };

    const CEChangeHandler = (ev) => {
        setCEId(ev);
        getSEs(ev.value);
    }
    const SEChangeHandler = (ev) => {
        setSEId(ev);
        setMechanicalDivisionData([]);
        getMechanicalDivisions(ev.value);
    }
    const MechanicalDivisionChangeHandler = (ev) => {
        setMechanicalDivisionId(ev);
        setMechanicalSubDivisionData([]);
        getMechanicalSubDivisions(ev.value);
    }
    const MechanicalSubDivisionChangeHandler = (ev) => {
        setMechanicalSubDivisionId(ev);
    }
    const CEOChangeHandler = (ev) => {
        setCEOId(ev);
        getSEOs(ev.value);
        getProjects(ev.value, 0, 0, 0);
    }
    const SEOChangeHandler = (ev) => {
        setSEOId(ev);
        setCivilDivisionData([]);
        getCivilDivisions(ev.value);
        getProjects(CEOId.value, ev.value, 0, 0);
    }
    const CivilDivisionChangeHandler = (ev) => {
        setCivilDivisionId(ev);
        setCivilSubDivisionData([]);
        getCivilSubDivisions(ev.value);
        getProjects(CEOId.value, SEOId.value, ev.value, 0);
    }
    const CivilSubDivisionChangeHandler = (ev) => {
        setCivilSubDivisionId(ev);
        getProjects(CEOId.value, SEOId.value, CivilDivisionId.value, ev.value);
    }
    const ProjectChangeHandler = (ev) => {
        setProjectId(ev);
        getAllocationDetails(ev.value);
    }
    const handleRadioChange = (wrId) => {
        setWorkId(wrId);
        getTaskDetails(wrId);
        getWorkStatusDetails(ProjectId.value, wrId);
    }
    const TaskChangeHandler = (ev) => {
        setTaskId(ev);
        getSubTaskDetails(ev.value);
    }
    const SubTaskChangeHandler = (ev) => {
        setSubTaskId(ev);
        getSubSubTaskDetails(ev.value);
    }
    const SubSubTaskChangeHandler = (ev) => {
        setSubSubTaskId(ev);
    }
    const StageChangeHandler = (ev) => {
        setStageId(ev);
        getWorkStageRemarks(ev.value);
    }
    const StageRemarkChangeHandler = (ev) => {
        setStageRemarkId(ev);
    }
    const clearHandler = () => {
        localStorage.setItem('ID', null);
        setState({ ...state, subtask: '', amount: '', worktargetcompleted: '' });
        const ce = ({ value: null, label: "Select CEO" });
        setCEOId(ce);
        const se = ({ value: null, label: "Select SEO" });
        setSEOId(se);
        const div = ({ value: null, label: "Select Civil Division" });
        setCivilDivisionId(div);
        const sub = ({ value: null, label: "Select Civil Sub Division" });
        setCivilSubDivisionId(sub);
        const pr = ({ value: null, label: "Select Project" });
        setProjectId(pr);
        const tk = ({ value: null, label: "Select Task" });
        setTaskId(tk);
        const sk = ({ value: null, label: "Select Sub Task" });
        setSubTaskId(sk);
        const ssk = ({ value: null, label: "Select Sub Sub Task" });
        setSubSubTaskId(ssk);
        setWorkStatusData([]);
        setAllocationData([]);
        setWorkId('');
    }

    const handleDownload = (fileName, filePath) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("FileName", fileName);
        formData.append("FilePath", filePath);
        fetch(
            service.DOWNLOAD_FILE,
            {
                method: 'POST',
                body: formData
            })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    fileName,
                );
                // Append to html link element page
                document.body.appendChild(link);
                // Start download
                link.click();
                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });

        setLoading(false);
    };
    // const handleEdit = (id) => {
    //     getDetailsById(id);
    //     localStorage.setItem("ID", JSON.stringify(id));
    // }
    const submitHandler = e => {
        e.preventDefault();
        setLoading(true);
        if (validate()) {
            const formData = new FormData();
            formData.append("StatusId", 0);
            formData.append("ProjectId", ProjectId.value);
            formData.append("WorkId", workId);
            formData.append("TaskId", taskId ? taskId.value : 0);
            formData.append("SubTaskId", subTaskId ? subTaskId.value : 0);
            formData.append("SubSubTaskId", subSubTaskId ? subSubTaskId.value : 0);
            formData.append("WorkStageId", stageId.value);
            formData.append("RemarkId", stageRemarkId.value);
            formData.append("StatusDate", (new Date(statusDate)).toUTCString());
            formData.append("Remarks", state.remark);
            formData.append("Amount", state.amount);
            formData.append("WorkTargetCompleted", state.worktargetcompleted);
            formData.append("File1", file1);
            formData.append("File2", file2);
            formData.append("File3", file3);
            formData.append("File4", file4);
            formData.append("File5", file5);
            formData.append("AddedBy", JSON.parse(sessionStorage.getItem('userData')).userId);
            formData.append("UpdatedBy", JSON.parse(sessionStorage.getItem('userData')).userId);
            fetch(
                service.ADD_UPDATE_WORK_STATUS,
                {
                    method: 'POST',
                    body: formData
                })
                .then(resp => resp.json())
                .then(respo => {
                    if (respo.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(respo.message);
                        localStorage.setItem('ID', null);
                        setFile1('');
                        setFile2('')
                        setFile3('');
                        setFile4('')
                        setFile5('');
                        setState({ ...state, remark: '', amount: '', worktargetcompleted: '' });
                        getWorkStatusDetails(ProjectId.value, workId);
                    }
                    else if (respo.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(respo.message);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(respo.message);
                    }
                });
        }
        setLoading(false);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Work Status
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="CE"
                                                    options={CEData} placeholder="Select CE"
                                                    value={CEId}
                                                    onChange={CEChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".ceId"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="SE"
                                                    options={SEData} placeholder="Select SE"
                                                    value={SEId}
                                                    onChange={SEChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".seId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="Mechanical Division"
                                                    options={MechanicalDivisionData} placeholder="Select Mechanical Division"
                                                    value={MechanicalDivisionId}
                                                    onChange={MechanicalDivisionChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".MechanicalDivisionId"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="Mechanical Sub Division"
                                                    options={MechanicalSubDivisionData} placeholder="Select Mechanical Sub Division"
                                                    value={MechanicalSubDivisionId}
                                                    onChange={MechanicalSubDivisionChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                    isDisabled
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".MechanicalSubDivisionId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell width="50%">
                                                <Select
                                                    name="Civil CEO"
                                                    options={CEOData} placeholder="Select CEO"
                                                    value={CEOId}
                                                    onChange={CEOChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".CEOId"]}</div>
                                            </TableCell>
                                            <TableCell width="50%">
                                                <Select
                                                    name="Civil SEO"
                                                    options={SEOData} placeholder="Select SEO"
                                                    value={SEOId}
                                                    onChange={SEOChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".SEOId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="Civil Division"
                                                    options={CivilDivisionData} placeholder="Select Civil Division"
                                                    value={CivilDivisionId}
                                                    onChange={CivilDivisionChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".CivilDivisionId"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="Civil Sub Division"
                                                    options={CivilSubDivisionData} placeholder="Select Civil Sub Division"
                                                    value={CivilSubDivisionId}
                                                    onChange={CivilSubDivisionChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".CivilSubDivisionId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="Project"
                                                    options={ProjectData} placeholder="Select Project"
                                                    value={ProjectId}
                                                    onChange={ProjectChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".ProjectId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan="2" align="center">
                                                <Table aria-label="simple table">
                                                    <TableRow style={{ backgroundColor: 'lightgrey' }}>
                                                        <TableCell style={{ fontWeight: 'bold' }}>Select</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>Project</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>Gate Type</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>Gate Number</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>Hoist Type</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>Hoist Number</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>Work Description</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>Work Date</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>AllocationData Date</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }}>Remarks</TableCell>
                                                    </TableRow>
                                                    <TableBody>
                                                        {allocationData.map((row) => (
                                                            <TableRow
                                                                key={row.workId}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell>
                                                                    <Radio onChange={() => handleRadioChange(row.workId)}
                                                                        value={row.workId} name="radio-buttons"
                                                                        checked={workId === row.workId} />
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">{row.project}</TableCell>
                                                                <TableCell component="th" scope="row">{row.gateType}</TableCell>
                                                                <TableCell component="th" scope="row">{row.gateNumber}</TableCell>
                                                                <TableCell component="th" scope="row">{row.hoistType}</TableCell>
                                                                <TableCell component="th" scope="row">{row.hoistNumber}</TableCell>
                                                                <TableCell component="th" scope="row">{row.workDescription}</TableCell>
                                                                <TableCell component="th" scope="row">{moment(row.workDate).format('DD/MM/YYYY')}</TableCell>
                                                                <TableCell component="th" scope="row">{moment(row.allocationDate).format('DD/MM/YYYY')}</TableCell>
                                                                <TableCell component="th" scope="row">{row.remarks}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".workIds"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="Task"
                                                    options={taskData} placeholder="Select Task"
                                                    value={taskId}
                                                    onChange={TaskChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".taskId"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="Sub Task"
                                                    options={subTaskData} placeholder="Select Sub Task"
                                                    value={subTaskId}
                                                    onChange={SubTaskChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".subTaskId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="Sub Sub Task"
                                                    options={subSubTaskData} placeholder="Select Sub Sub Task"
                                                    value={subSubTaskId}
                                                    onChange={SubSubTaskChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".subSubTaskId"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="Work Stage"
                                                    options={stageData} placeholder="Select Work Stage"
                                                    value={stageId}
                                                    onChange={StageChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".stageId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="Work Stage Remarks"
                                                    options={stageRemarkData} placeholder="Select Remarks"
                                                    value={stageRemarkId}
                                                    onChange={StageRemarkChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".stageRemarkId"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Remarks" fullWidth value={state.remark || ''}
                                                    name="remark"
                                                    onChange={changeHandler}
                                                    id="remark"
                                                    required
                                                    multiline />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".remark"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='left' style={{ fontSize: '12px' }}>
                                                Status Date
                                                <DatePicker
                                                    id="statusDate"
                                                    selected={statusDate}
                                                    onChange={date => setStatusDate(date)}
                                                    dateFormat="MM/dd/yyyy"
                                                    tabIndex={0}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".statusDate"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Amount" fullWidth value={state.amount || ''}
                                                    name="amount"
                                                    onChange={changeHandler}
                                                    id="amount"
                                                    required
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".amount"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="Work Target Completed" fullWidth value={state.worktargetcompleted || ''}
                                                    name="worktargetcompleted"
                                                    onChange={changeHandler}
                                                    id="worktargetcompleted"
                                                    required
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".worktargetcompleted"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                File 1
                                                <br />
                                                <TextField
                                                    id="file1"
                                                    type="file"
                                                    onChange={ev => setFile1(ev.target.files[0])}
                                                    style={{ width: 200 }}
                                                    required
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".file1"]}</div>
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                File 2
                                                <br />
                                                <TextField
                                                    id="file2"
                                                    type="file"
                                                    onChange={ev => setFile2(ev.target.files[0])}
                                                    style={{ width: 200 }}
                                                    required
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".file2"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                File 3
                                                <br />
                                                <TextField
                                                    id="file3"
                                                    type="file"
                                                    onChange={ev => setFile3(ev.target.files[0])}
                                                    style={{ width: 200 }}
                                                    required
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".file3"]}</div>
                                            </TableCell>
                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                File 4
                                                <br />
                                                <TextField
                                                    id="file4"
                                                    type="file"
                                                    onChange={ev => setFile4(ev.target.files[0])}
                                                    style={{ width: 200 }}
                                                    required
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".file4"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                File 5
                                                <br />
                                                <TextField
                                                    id="file5"
                                                    type="file"
                                                    onChange={ev => setFile5(ev.target.files[0])}
                                                    style={{ width: 200 }}
                                                    required
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".file5"]}</div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <div>
                                            <MDButton color="info" onClick={submitHandler}>
                                                SAVE
                                            </MDButton>&nbsp;&nbsp;&nbsp;&nbsp;
                                            <MDButton color="info" onClick={clearHandler}>
                                                CLEAR
                                            </MDButton>
                                        </div>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>
                            </Card>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow style={{ backgroundColor: 'lightgrey' }}>
                                                <TableCell style={{ fontWeight: 'bold' }}>Task</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Sub Task</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Sub Sub Task</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Stage</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Stage Remarks</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Stage Date</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Remarks</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Amount</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Work Target Completed</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>File1 </TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>File2</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>File3</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>File4</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>File5</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Approve/Reject Remarks</TableCell>
                                                {/* <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell> */}
                                            </TableRow>
                                            <TableBody>
                                                {workStatusData.map((row) => (
                                                    <TableRow
                                                        key={row.statusId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">{row.task}</TableCell>
                                                        <TableCell component="th" scope="row">{row.subTask}</TableCell>
                                                        <TableCell component="th" scope="row">{row.subSubTask}</TableCell>
                                                        <TableCell component="th" scope="row">{row.workStage}</TableCell>
                                                        <TableCell component="th" scope="row">{row.workStageRemarks}</TableCell>
                                                        <TableCell component="th" scope="row">{moment(row.statusDate).format('DD/MM/YYYY')}</TableCell>
                                                        <TableCell component="th" scope="row">{row.remarks}</TableCell>
                                                        <TableCell component="th" scope="row">{row.amount}</TableCell>
                                                        <TableCell component="th" scope="row">{row.workTargetCompleted}</TableCell>
                                                        <TableCell>
                                                            <MDButton onClick={() => handleDownload(row.fileName1, row.filePath1)}>{row.fileName1}</MDButton>
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDButton onClick={() => handleDownload(row.fileName2, row.filePath2)}>{row.fileName2}</MDButton>
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDButton onClick={() => handleDownload(row.fileName3, row.filePath3)}>{row.fileName3}</MDButton>
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDButton onClick={() => handleDownload(row.fileName4, row.filePath4)}>{row.fileName4}</MDButton>
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDButton onClick={() => handleDownload(row.fileName5, row.filePath5)}>{row.fileName5}</MDButton>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">{row.status}</TableCell>
                                                        <TableCell component="th" scope="row">{row.approveRemarks}</TableCell>
                                                        {/* <TableCell><MDButton onClick={() => handleEdit(row.statusId)}>Edit</MDButton></TableCell> */}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default WorkStatus;