// import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from 'react-select';
import * as service from '../../../services/service';

function ProjectGates() {
    const [state, setState] = useState({ gatenumber: '' });
    const [gateTypeData, setGateTypeData] = useState([]);
    const [gateTypeId, setGateTypeId] = useState('');
    const [CEOData, setCEOData] = useState([]);
    const [SEOData, setSEOData] = useState([]);
    const [CivilDivisionData, setCivilDivisionData] = useState([]);
    const [CivilSubDivisionData, setCivilSubDivisionData] = useState([]);
    const [ProjectData, setProjectData] = useState([]);
    const [CEOId, setCEOId] = useState('');
    const [SEOId, setSEOId] = useState('');
    const [CivilDivisionId, setCivilDivisionId] = useState('');
    const [CivilSubDivisionId, setCivilSubDivisionId] = useState('');
    const [ProjectId, setProjectId] = useState('');
    const [data, setData] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const getGateTypes = () => {
        fetch(
            service.GATE_TYPE_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.gateTypeId, label: item.gateType }));
                setGateTypeData(data2);
            });
    };
    const getCEOs = () => {
        fetch(
            service.CIVIL_CEO_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.civilCEOId, label: item.civilCEO }));
                setCEOData(data2);
            });
    };

    const getSEOs = (id) => {
        fetch(
            service.GET_CIVIL_SEO_BY_CEO_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CivilCEOId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.civilSEOId, label: item.civilSEO }));
                setSEOData(data2);
            });
    };

    const getCivilDivisions = (id) => {
        fetch(
            service.GET_CIVIL_DIVISION_BY_SEO_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CivilSEOId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.civilDivisionId, label: item.civilDivision }));
                setCivilDivisionData(data2);
            });
    };

    const getCivilSubDivisions = (id) => {
        fetch(
            service.GET_CIVIL_SUB_DIVISION_BY_CIVIL_DIV_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CivilDivisionId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.civilSubDivisionId, label: item.civilSubDivision }));
                setCivilSubDivisionData(data2);
            });
    };

    const getProjects = (ceoId, seoId, divId, subId) => {        
        fetch(
            service.PROJECT_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CivilCEOId: ceoId,
                    CivilSEOId: seoId,
                    CivilDivisionId: divId,
                    CivilSubDivisionId: subId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.projectId, label: item.project }));
                setProjectData(data2);
            });
    };

    const getDetails = (id) => {
        const errs = {};
        fetch(
            service.PROJECT_GATE_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProjectId: id,
                })
            })
            .then(res => res.json())
            .then(result => {                
                setData(result);
            })
            .catch(err => {
                if (err) {
                    errs[".invalid"] = err;
                    setErrors(err);
                };
            });
    };

    const getDetailsById = (id) => {
        setLoading(true);
        const errs = {};
        fetch(
            service.GET_PROJECT_GATE_BY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProjectGateId: id,
                })
            })
            .then(res => res.json())
            .then(result => {                
                if (result.message.messageType === 'Success') {
                    setState({ ...state, gatenumber: result.gateNumber });
                    const st = ({ value: result.gateTypeId, label: result.gateType });
                    setGateTypeId(st);
                }
                else {
                    errs[".invalid"] = result.message.message;
                    setErrors(errs);
                }

            });
        setLoading(false);
    };

    const handleEdit = (id) => {
        getDetailsById(id);
        localStorage.setItem("ID", JSON.stringify(id));
    }

    useEffect(() => {
        setLoading(true);
        getCEOs();
        getGateTypes();
        setLoading(false);
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!gateTypeId) {
            isValid = false;
            errs[".gateTypeId"] = "Please select Gate Type";
        }
        if (!ProjectId) {
            isValid = false;
            errs[".ProjectId"] = "Please select Project";
        }
        if (!state.gatenumber) {
            isValid = false;
            errs[".gatenumber"] = "Please enter Gate Number";
        }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };
    const GateTypeChangeHandler = (ev) => {
        setGateTypeId(ev);        
    }
    const CEOChangeHandler = (ev) => {
        setCEOId(ev);
        getSEOs(ev.value);
        getProjects(ev.value, 0, 0, 0);
    }
    const SEOChangeHandler = (ev) => {
        setSEOId(ev);
        setCivilDivisionData([]);
        getCivilDivisions(ev.value);
        getProjects(CEOId.value, ev.value, 0, 0);
    }
    const CivilDivisionChangeHandler = (ev) => {
        setCivilDivisionId(ev);
        setCivilSubDivisionData([]);
        getCivilSubDivisions(ev.value);
        getProjects(CEOId.value, SEOId.value, ev.value, 0);
    }
    const CivilSubDivisionChangeHandler = (ev) => {
        setCivilSubDivisionId(ev);
        getProjects(CEOId.value, SEOId.value, CivilDivisionId.value, ev.value);
    }
    const ProjectChangeHandler = (ev) => {
        setProjectId(ev);
        getDetails(ev.value);
    }
    const clearHandler = () => {
        localStorage.setItem('ID', null);
        setState({ ...state, gatenumber: '' });
        const st = ({ value: '', label: "Select Gate Type" });
        setGateTypeId(st);
        const ce = ({ value: null, label: "Select CEO" });
        setCEOId(ce);
        const se = ({ value: null, label: "Select SEO" });
        setSEOId(se);
        const div = ({ value: null, label: "Select Division" });
        setCivilDivisionId(div);
        const sub = ({ value: null, label: "Select Sub Division" });
        setCivilSubDivisionId(sub);
        const pr = ({ value: null, label: "Select Project" });
        setProjectId(pr);
        setData([]);
    }

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            fetch(
                service.ADD_UPDATE_PROJECT_GATE,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        ProjectGateId: JSON.parse(localStorage.getItem('ID')) !== null ? JSON.parse(localStorage.getItem('ID')) : 0,
                        GateNumber: state.gatenumber,
                        GateTypeId: gateTypeId.value,
                        ProjectId: ProjectId.value,
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                        UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        localStorage.setItem('ID', null);
                        getDetails(ProjectId.value);
                        setState({ ...state, gatenumber: '' });
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Project Gates
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="Civil CEO"
                                                    options={CEOData} placeholder="Select CEO"
                                                    value={CEOId}
                                                    onChange={CEOChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".ceo"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="Civil SEO"
                                                    options={SEOData} placeholder="Select SEO"
                                                    value={SEOId}
                                                    onChange={SEOChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".seo"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="Civil Division"
                                                    options={CivilDivisionData} placeholder="Select Division"
                                                    value={CivilDivisionId}
                                                    onChange={CivilDivisionChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".mechanicaldivision"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="Civil Sub Division"
                                                    options={CivilSubDivisionData} placeholder="Select Sub Division"
                                                    value={CivilSubDivisionId}
                                                    onChange={CivilSubDivisionChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".mechanicalsubdivision"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="Project"
                                                    options={ProjectData} placeholder="Select Project"
                                                    value={ProjectId}
                                                    onChange={ProjectChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".ProjectId"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="Gate Type"
                                                    options={gateTypeData} placeholder="Select Gate Type"
                                                    value={gateTypeId}
                                                    onChange={GateTypeChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gateTypeId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="Gate Number" fullWidth value={state.gatenumber || ''}
                                                    name="gatenumber"
                                                    onChange={changeHandler}
                                                    id="gatenumber"
                                                    required />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gatenumber"]}</div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <div>
                                            <MDButton color="info" onClick={submitHandler}>
                                                SAVE
                                            </MDButton>&nbsp;&nbsp;&nbsp;&nbsp;
                                            <MDButton color="info" onClick={clearHandler}>
                                                CLEAR
                                            </MDButton>
                                        </div>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>
                            </Card>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '80%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow style={{ backgroundColor: 'lightgrey' }}>
                                                <TableCell style={{ fontWeight: 'bold' }}>Project</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Gate Type</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Gate Number</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                                            </TableRow>
                                            <TableBody>
                                                {data.map((row) => (
                                                    <TableRow
                                                        key={row.projectGateId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">{row.project}</TableCell>
                                                        <TableCell component="th" scope="row">{row.gateType}</TableCell>
                                                        <TableCell component="th" scope="row">{row.gateNumber}</TableCell>
                                                        <TableCell><MDButton onClick={() => handleEdit(row.projectGateId)}>Edit</MDButton></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default ProjectGates;