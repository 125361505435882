import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Oval } from 'react-loader-spinner';
import Select from "react-select";
import * as service from '../../../services/service';

function User() {
    const [state, setState] = useState({ username: '', emailid: '', mobileno: '', password: '' });
    const [data, setData] = useState([]);
    const [designationId, setDesignationId] = useState('');
    const [designationData, setDesignationData] = useState([]);
    const [CEData, setCEData] = useState([]);
    const [SEData, setSEData] = useState([]);
    const [MechanicalDivisionData, setMechanicalDivisionData] = useState([]);
    const [MechanicalSubDivisionData, setMechanicalSubDivisionData] = useState([]);
    const [CEId, setCEId] = useState('');
    const [SEId, setSEId] = useState('');
    const [MechanicalDivisionId, setMechanicalDivisionId] = useState('');
    const [MechanicalSubDivisionId, setMechanicalSubDivisionId] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [userLevel, setUserLevel] = useState('');
    const [active, setActive] = useState('');
    const userLevelData = [{ label: "CE", value: "CE" }, { label: "SE", value: "SE" },
    { label: "Mechanical Division", value: "Mechanical Division" }, { label: "Mechanical Sub Division", value: "Mechanical Sub Division" }];
    const activeData = [{ label: "Active", value: true }, { label: "Inactive", value: false }];
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const getDesignations = () => {
        fetch(
            service.DESIGNATION_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.designationId, label: item.designation }));
                setDesignationData(data2);
            });
    };

    const getCEs = () => {
        fetch(
            service.CE_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.ceId, label: item.ce }));
                setCEData(data2);
            });
    };

    const getSEs = (id) => {
        fetch(
            service.GET_SE_BY_CE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CEId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.seId, label: item.se }));
                setSEData(data2);
            });
    };

    const getMechanicalDivisions = (id) => {
        fetch(
            service.GET_MECHANICAL_DIVISION_BY_SE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    SEId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.mechanicalDivisionId, label: item.mechanicalDivision }));
                setMechanicalDivisionData(data2);
            });
    };

    const getMechanicalSubDivisions = (id) => {
        fetch(
            service.GET_MECHANICAL_SUB_DIVISION_BY_MECH_DIV_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    MechanicalDivisionId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.mechanicalSubDivisionId, label: item.mechanicalSubDivision }));
                setMechanicalSubDivisionData(data2);
            });
    };

    const getDetails = () => {
        const errs = {};
        fetch(
            service.USERS_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json())
            .then(result => {
                setData(result);
            })
            .catch(err => {
                if (err) {
                    errs[".invalid"] = err;
                    setErrors(err);
                };
            });
    };

    const getDetailsById = (id) => {
        setLoading(true);
        const errs = {};
        fetch(
            service.GET_USER_BY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    UserId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.message.messageType === 'Success') {
                    setState({
                        ...state, username: result.userName, emailid: result.emailId, mobileno: result.mobileNo, password: result.password
                    });

                    const lev = ({ value: result.userLevel, label: result.userLevel });
                    setUserLevel(lev);
                    const ceData = ({ value: result.ceId, label: result.ce });
                    setCEId(ceData);
                    getSEs(ceData.value);
                    const seData = ({ value: result.seId, label: result.se });
                    setSEId(seData);
                    getMechanicalDivisions(seData.value);
                    const divData = ({ value: result.mechanicalDivisionId, label: result.mechanicalDivision });
                    setMechanicalDivisionId(divData);
                    getMechanicalSubDivisions(divData.value);
                    const subData = ({ value: result.mechanicalSubDivisionId, label: result.mechanicalSubDivision });
                    setMechanicalSubDivisionId(subData);

                    const des = ({ value: result.designationId, label: result.designation });
                    setDesignationId(des);
                    const ac = ({ value: result.isActive, label: result.isActive ? 'Active' : 'Inactive' });
                    setActive(ac);
                }
                else {
                    errs[".invalid"] = result.message.message;
                    setErrors(errs);
                }
            });
        setLoading(false);
    };
    const handleEdit = (id) => {
        getDetailsById(id);
        localStorage.setItem("ID", JSON.stringify(id));
    }

    useEffect(() => {
        setLoading(true);
        getDesignations();
        getCEs();
        getDetails();

        const ac = ({ value: true, label: "Active" });
        setActive(ac);
        setLoading(false);
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!state.username) {
            isValid = false;
            errs[".username"] = "Please enter user name";
        }
        if (!designationId) {
            isValid = false;
            errs[".designation"] = "Please select designation";
        }
        if (!userLevel) {
            isValid = false;
            errs[".userLevel"] = "Please select user level";
        }
        if (userLevel.value === 'CE' && !CEId) {
            isValid = false;
            errs[".ce"] = "Please select CE";
        }
        if (userLevel.value === 'SE' && !SEId) {
            isValid = false;
            errs[".se"] = "Please select SE";
        }
        if (userLevel.value === 'Mechanical Division' && !MechanicalDivisionId) {
            isValid = false;
            errs[".mechanicaldivision"] = "Please select Mechanical Division";
        }
        if (userLevel.value === 'Mechanical Sub Division' && !MechanicalSubDivisionId) {
            isValid = false;
            errs[".mechanicalsubdivision"] = "Please select Mechanical Sub Division";
        }
        if (!state.emailid) {
            isValid = false;
            errs[".emailId"] = "Please enter emailId";
        }
        if (!state.mobileno) {
            isValid = false;
            errs[".mobileno"] = "Please enter mobile no.";
        }
        setErrors(errs);
        return isValid;
    }

    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };
    const CEChangeHandler = (ev) => {
        setCEId(ev);
        getSEs(ev.value);
    }
    const SEChangeHandler = (ev) => {
        setSEId(ev);
        setMechanicalDivisionData([]);
        getMechanicalDivisions(ev.value);
    }
    const MechanicalDivisionChangeHandler = (ev) => {
        setMechanicalDivisionId(ev);
        setMechanicalSubDivisionData([]);
        getMechanicalSubDivisions(ev.value);
    }
    const MechanicalSubDivisionChangeHandler = (ev) => {
        setMechanicalSubDivisionId(ev);
    }
    const DesignationChangeHandler = (ev) => {
        setDesignationId(ev);
    }
    const UserLevelChangeHandler = (ev) => {
        setUserLevel(ev);
    }
    const ActiveChangeHandler = (ev) => {
        setActive(ev);
    }
    const clearHandler = () => {
        localStorage.setItem('ID', null);
        setState({ ...state, username: '', emailid: '', mobileno: '', password: '' });
        const lv = ({ value: '', label: "Select User Level" });
        setUserLevel(lv);
        const ce = ({ value: '', label: "Select CE" });
        setCEId(ce);
        const se = ({ value: '', label: "Select SE" });
        setSEId(se);
        const div = ({ value: '', label: "Select Division" });
        setMechanicalDivisionId(div);
        const sub = ({ value: '', label: "Select Sub Division" });
        setMechanicalSubDivisionId(sub);
        const des = ({ value: '', label: "Select Designation" });
        setDesignationId(des);
    }

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            fetch(
                service.ADD_UPDATE_USER,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        UserId: JSON.parse(localStorage.getItem('ID')) !== null ? JSON.parse(localStorage.getItem('ID')) : 0,
                        UserName: state.username,
                        MobileNo: state.mobileno,
                        EmailId: state.emailid,
                        Password: state.password ? state.password : state.mobileno, // `${state.firstname}@123`,
                        DesignationId: designationId.value,
                        UserLevel: userLevel.value,
                        CEId: CEId.value,
                        SEId: SEId ? SEId.value : 0,
                        MechanicalDivisionId: MechanicalDivisionId ? MechanicalDivisionId.value : 0,
                        MechanicalSubDivisionId: MechanicalSubDivisionId ? MechanicalSubDivisionId.value : 0,
                        IsActive: active.value,
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                        UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        localStorage.setItem('ID', null);
                        window.location.reload();
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        errs[".invalid"] = result.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    User Details
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="User Name" fullWidth value={state.username || ''}
                                                    name="username"
                                                    onChange={changeHandler}
                                                    id="username"
                                                    required />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".username"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Email" fullWidth value={state.emailid || ''}
                                                    name="emailid"
                                                    type="email"
                                                    onChange={changeHandler}
                                                    id="emailid"
                                                    required
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".emailId"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Mobile No" fullWidth value={state.mobileno || ''}
                                                    name="mobileno"
                                                    onChange={changeHandler}
                                                    id="mobileno"
                                                    required
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".mobileno"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    options={designationData}
                                                    name="designation"
                                                    value={designationId}
                                                    onChange={DesignationChangeHandler}
                                                    placeholder="Select Designation"
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".designation"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    options={userLevelData}
                                                    name="userLevel"
                                                    value={userLevel}
                                                    onChange={UserLevelChangeHandler}
                                                    placeholder="Select User Level"
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".userLevel"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="CE"
                                                    options={CEData} placeholder="Select CE"
                                                    value={CEId}
                                                    onChange={CEChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".ce"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="SE"
                                                    options={SEData} placeholder="Select SE"
                                                    value={SEId}
                                                    onChange={SEChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".se"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="Mechanical Division"
                                                    options={MechanicalDivisionData} placeholder="Select Division"
                                                    value={MechanicalDivisionId}
                                                    onChange={MechanicalDivisionChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".mechanicaldivision"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="Mechanical Sub Division"
                                                    options={MechanicalSubDivisionData} placeholder="Select Sub Division"
                                                    value={MechanicalSubDivisionId}
                                                    onChange={MechanicalSubDivisionChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".mechanicalsubdivision"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="Active"
                                                    options={activeData}
                                                    value={active}
                                                    onChange={ActiveChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".active"]}</div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <div>
                                            <MDButton color="info" onClick={submitHandler}>
                                                SAVE
                                            </MDButton>&nbsp;&nbsp;&nbsp;&nbsp;
                                            <MDButton color="info" onClick={clearHandler}>
                                                CLEAR
                                            </MDButton>
                                        </div>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>

                            </Card>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">

                                            <TableRow style={{ backgroundColor: 'lightgrey' }}>
                                                <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Email</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Mobile No.</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Designation</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>User Level</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>CE</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>SE</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Division</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Sub Division</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Active</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                                            </TableRow>

                                            <TableBody>
                                                {data.map((row) => (
                                                    <TableRow
                                                        key={row.userId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>{row.userName}</TableCell>
                                                        <TableCell>{row.emailId}</TableCell>
                                                        <TableCell>{row.mobileNo}</TableCell>
                                                        <TableCell>{row.designation}</TableCell>
                                                        <TableCell>{row.userLevel}</TableCell>
                                                        <TableCell>{row.ce}</TableCell>
                                                        <TableCell>{row.se}</TableCell>
                                                        <TableCell>{row.mechanicalDivision}</TableCell>
                                                        <TableCell>{row.mechanicalSubDivision}</TableCell>
                                                        <TableCell>{row.isActive ? 'Active' : 'Inactive'}</TableCell>
                                                        <TableCell><MDButton onClick={() => handleEdit(row.userId)}>Edit</MDButton></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                }
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default User;