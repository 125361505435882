// import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment';
import * as service from '../../../services/service';

function WorkDetails() {
    const [state, setState] = useState({ workdescription: '', worktarget: '' });
    const [gateTypeData, setGateTypeData] = useState([]);
    const [gateTypeId, setGateTypeId] = useState('');
    const [hoistTypeData, setHoistTypeData] = useState([]);
    const [hoistTypeId, setHoistTypeId] = useState('');
    const [CEOData, setCEOData] = useState([]);
    const [SEOData, setSEOData] = useState([]);
    const [CivilDivisionData, setCivilDivisionData] = useState([]);
    const [CivilSubDivisionData, setCivilSubDivisionData] = useState([]);
    const [ProjectData, setProjectData] = useState([]);
    const [CEOId, setCEOId] = useState('');
    const [SEOId, setSEOId] = useState('');
    const [CivilDivisionId, setCivilDivisionId] = useState('');
    const [CivilSubDivisionId, setCivilSubDivisionId] = useState('');
    const [ProjectId, setProjectId] = useState('');
    const [gateNumberData, setGateNumberData] = useState([]);
    const [gateNumber, setGateNumber] = useState('');
    const [hoistNumberData, setHoistNumberData] = useState([]);
    const [hoistNumber, setHoistNumber] = useState('');
    const [workType, setWorkType] = useState('');
    const [programType, setProgramType] = useState('');
    const [workDate, setWorkDate] = useState(new Date());
    const [data, setData] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const workTypeData = [{ value: "New Manufacturing", label: "New Manufacturing" }, { value: "R & M", label: "R & M" }];
    const programTypeData = [{ value: "Deployment Program", label: "Deployment Program" }, { value: "Pre Monsoon Program", label: "Pre Monsoon Program" },
    { value: "Post Monsoon Program", label: "Post Monsoon Program" }, { value: "Unplanned Program", label: "Unplanned Program" }];
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const getCEOs = () => {
        fetch(
            service.CIVIL_CEO_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.civilCEOId, label: item.civilCEO }));
                setCEOData(data2);
            });
    };

    const getSEOs = (id) => {
        fetch(
            service.GET_CIVIL_SEO_BY_CEO_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CivilCEOId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.civilSEOId, label: item.civilSEO }));
                setSEOData(data2);
            });
    };

    const getCivilDivisions = (id) => {
        fetch(
            service.GET_CIVIL_DIVISION_BY_SEO_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CivilSEOId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.civilDivisionId, label: item.civilDivision }));
                setCivilDivisionData(data2);
            });
    };

    const getCivilSubDivisions = (id) => {
        fetch(
            service.GET_CIVIL_SUB_DIVISION_BY_CIVIL_DIV_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CivilDivisionId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.civilSubDivisionId, label: item.civilSubDivision }));
                setCivilSubDivisionData(data2);
            });
    };

    const getProjects = (ceoId, seoId, divId, subId) => {
        fetch(
            service.PROJECT_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CivilCEOId: ceoId,
                    CivilSEOId: seoId,
                    CivilDivisionId: divId,
                    CivilSubDivisionId: subId
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.projectId, label: item.project }));
                setProjectData(data2);
            });
    };

    const getGateTypes = (id) => {
        fetch(
            service.GET_PROJECT_GATE_TYPES,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProjectId: id,
                })

            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.gateTypeId, label: item.gateType }));
                setGateTypeData(data2);
            });
    };

    const getGateNumbers = (pId, id) => {
        fetch(
            service.GET_PROJECT_GATE_BY_GATE_TYPE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProjectId: pId,
                    GateTypeId: id
                })

            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.projectGateId, label: item.gateNumber }));
                const all = ({ value: "All", label: "All" });
                data2.unshift(all);
                setGateNumberData(data2);
            });
    };

    const getHoistTypes = (id) => {
        fetch(
            service.GET_PROJECT_HOIST_TYPES,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProjectId: id,
                })

            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.hoistTypeId, label: item.hoistType }));
                setHoistTypeData(data2);
            });
    };

    const getHoistNumbers = (pId, id) => {
        fetch(
            service.GET_PROJECT_HOIST_BY_HOIST_TYPE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProjectId: pId,
                    HoistTypeId: id
                })

            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.projectHoistId, label: item.hoistNumber }));
                const all = ({ value: "All", label: "All" });
                data2.unshift(all);
                setHoistNumberData(data2);
            });
    };

    const getDetails = (id) => {
        const errs = {};
        fetch(
            service.WORK_DETAILS_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProjectId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                setData(result);
            })
            .catch(err => {
                if (err) {
                    errs[".invalid"] = err;
                    setErrors(err);
                };
            });
    };

    // const getDetailsById = (id) => {
    //     setLoading(true);
    //     const errs = {};
    //     fetch(
    //         service.GET_PROJECT_GATE_BY_ID,
    //         {
    //             method: 'POST',
    //             headers: {
    //                 'Accept': 'application/json',
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify({
    //                 ProjectGateId: id,
    //             })
    //         })
    //         .then(res => res.json())
    //         .then(result => {                
    //             if (result.message.messageType === 'Success') {
    //                 setState({ ...state, gatenumber: result.gateNumber });
    //                 const st = ({ value: result.gateTypeId, label: result.gateType });
    //                 setGateTypeId(st);
    //             }
    //             else {
    //                 errs[".invalid"] = result.message.message;
    //                 setErrors(errs);
    //             }

    //         });
    //     setLoading(false);
    // };

    // const handleEdit = (id) => {
    //     getDetailsById(id);
    //     localStorage.setItem("ID", JSON.stringify(id));
    // }

    useEffect(() => {
        setLoading(true);
        getCEOs();
        setLoading(false);
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!CEOId) {
            isValid = false;
            errs[".CEOId"] = "Please select CEO";
        }
        if (!SEOId) {
            isValid = false;
            errs[".SEOId"] = "Please select SEO";
        }
        if (!CivilDivisionId) {
            isValid = false;
            errs[".CivilDivisionId"] = "Please select Civil Division";
        }
        if (!CivilSubDivisionId) {
            isValid = false;
            errs[".CivilSubDivisionId"] = "Please select Civil Sub Division";
        }
        if (!ProjectId) {
            isValid = false;
            errs[".ProjectId"] = "Please select Project";
        }
        if (!gateTypeId && !hoistTypeId) {
            isValid = false;
            errs[".gateTypeId"] = "Please select Either Gate Type OR Hoist Type";
        }
        if (gateTypeId && hoistTypeId) {
            isValid = false;
            errs[".gateTypeId"] = "Please select Either Gate Type OR Hoist Type";
        }
        if (!gateNumber && !hoistNumber) {
            isValid = false;
            errs[".gatenumber"] = "Please Select Either Gate Number OR Hoist Number";
        }
        if (gateNumber && hoistNumber) {
            isValid = false;
            errs[".gatenumber"] = "Please Select Either Gate Number OR Hoist Number";
        }
        if (!workDate) {
            isValid = false;
            errs[".workDate"] = "Please select date";
        }
        if (!workType) {
            isValid = false;
            errs[".workType"] = "Please select Work Type";
        }
        if (!programType) {
            isValid = false;
            errs[".programType"] = "Please select Program Type";
        }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });

        if (event.target.name === 'worktarget') {
            if (Number.isNaN(parseFloat(event.target.value))) {
                // eslint-disable-next-line
                alert("Please enter numbers only");
                event.target.value = ""; // eslint-disable-line no-param-reassign
            }
        }
    };

    const CEOChangeHandler = (ev) => {
        setCEOId(ev);
        getSEOs(ev.value);
        getProjects(ev.value, 0, 0, 0);
    }
    const SEOChangeHandler = (ev) => {
        setSEOId(ev);
        setCivilDivisionData([]);
        getCivilDivisions(ev.value);
        getProjects(CEOId.value, ev.value, 0, 0);
    }
    const CivilDivisionChangeHandler = (ev) => {
        setCivilDivisionId(ev);
        setCivilSubDivisionData([]);
        getCivilSubDivisions(ev.value);
        getProjects(CEOId.value, SEOId.value, ev.value, 0);
    }
    const CivilSubDivisionChangeHandler = (ev) => {
        setCivilSubDivisionId(ev);
        getProjects(CEOId.value, SEOId.value, CivilDivisionId.value, ev.value);
    }
    const ProjectChangeHandler = (ev) => {
        setProjectId(ev);
        getGateTypes(ev.value);
        getHoistTypes(ev.value);
        getDetails(ev.value);
    }
    const GateTypeChangeHandler = (ev) => {
        setGateTypeId(ev);
        getGateNumbers(ProjectId.value, ev.value);
    }
    const HoistTypeChangeHandler = (ev) => {
        setHoistTypeId(ev);
        getHoistNumbers(ProjectId.value, ev.value);
    }
    const GateNumberChangeHandler = (ev) => {
        setGateNumber(ev);
    }
    const HoistNumberChangeHandler = (ev) => {
        setHoistNumber(ev);
    }
    const WorkTypeChangeHandler = (ev) => {
        setWorkType(ev);
    }
    const ProgramTypeChangeHandler = (ev) => {
        setProgramType(ev);
    }

    const clearHandler = () => {
        // localStorage.setItem('ID', null);
        setState({ ...state, workdescription: '', worktarget: '' });
        const ce = ({ value: null, label: "Select CEO" });
        setCEOId(ce);
        const se = ({ value: null, label: "Select SEO" });
        setSEOId(se);
        const div = ({ value: null, label: "Select Division" });
        setCivilDivisionId(div);
        const sub = ({ value: null, label: "Select Sub Division" });
        setCivilSubDivisionId(sub);
        const pr = ({ value: null, label: "Select Project" });
        setProjectId(pr);
        const gt = ({ value: '', label: "Select Gate Type" });
        setGateTypeId(gt);
        const gn = ({ value: '', label: "Select Gate Number" });
        setGateNumber(gn);
        const ht = ({ value: '', label: "Select Hoist Type" });
        setHoistTypeId(ht);
        const hn = ({ value: '', label: "Select Hoist Number" });
        setHoistNumber(hn);
        const wt = ({ value: '', label: "Select Work Type" });
        setWorkType(wt);
        const pt = ({ value: '', label: "Select Program Number" });
        setProgramType(pt);
        setData([]);
    }

    const SaveData = (prGateId, prHoistId) => {
        const errs = {};
        fetch(
            service.ADD_UPDATE_WORK_DETAILS,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    WorkId: 0, // JSON.parse(localStorage.getItem('ID')) !== null ? JSON.parse(localStorage.getItem('ID')) : 0,
                    ProjectId: ProjectId.value,
                    ProjectGateId: prGateId,
                    ProjectHoistId: prHoistId,
                    WorkDescription: state.workdescription,
                    WorkType: workType.value,
                    WorkDate: moment(workDate).format("MM/DD/YYYY"),
                    ProgramType: programType.value,
                    WorkTarget: state.worktarget,
                    AddedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                    UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.messageType === 'Failed') {
                    // eslint-disable-next-line
                    alert(result.message);
                }
                else {
                    errs[".invalid"] = result.message.message;
                    setErrors(errs);
                }
            });
    }

    const submitHandler = e => {
        e.preventDefault();
        setLoading(true);
        if (validate()) {
            if (gateNumber && gateNumber.value === 'All') {
                gateNumberData.forEach(element => {
                    if (element.value !== 'All') {
                        SaveData(element.value, 0);
                    }
                });
            }
            else if (gateNumber && gateNumber.value !== 'All') {
                SaveData(gateNumber.value, 0);
            }
            else if (hoistNumber && hoistNumber.value === 'All') {
                hoistNumberData.forEach(element => {
                    SaveData(0, element.value);
                });
            }
            else if (hoistNumber && hoistNumber.value !== 'All') {
                SaveData(0, hoistNumber.value);
            }
            // eslint-disable-next-line
            alert("Data Saved Successfully");
            getDetails(ProjectId.value);
            setState({ ...state, workdescription: '', worktarget: '' });
        }
        setLoading(false);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Work Details
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell width="50%">
                                                <Select
                                                    name="Civil CEO"
                                                    options={CEOData} placeholder="Select CEO"
                                                    value={CEOId}
                                                    onChange={CEOChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".CEOId"]}</div>
                                            </TableCell>
                                            <TableCell width="50%">
                                                <Select
                                                    name="Civil SEO"
                                                    options={SEOData} placeholder="Select SEO"
                                                    value={SEOId}
                                                    onChange={SEOChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".SEOId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="Civil Division"
                                                    options={CivilDivisionData} placeholder="Select Division"
                                                    value={CivilDivisionId}
                                                    onChange={CivilDivisionChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".CivilDivisionId"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="Civil Sub Division"
                                                    options={CivilSubDivisionData} placeholder="Select Sub Division"
                                                    value={CivilSubDivisionId}
                                                    onChange={CivilSubDivisionChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".CivilSubDivisionId"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="Project"
                                                    options={ProjectData} placeholder="Select Project"
                                                    value={ProjectId}
                                                    onChange={ProjectChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".ProjectId"]}</div>
                                            </TableCell>
                                            <TableCell align='left' style={{ fontSize: '12px' }}>
                                                Work Date
                                                <DatePicker
                                                    id="workDate"
                                                    selected={workDate}
                                                    onChange={date => setWorkDate(date)}
                                                    dateFormat="MM/dd/yyyy"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="scroll"
                                                    yearDropdownItemNumber={80}
                                                    tabIndex={0}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".workDate"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="Gate Type"
                                                    options={gateTypeData} placeholder="Select Gate Type"
                                                    value={gateTypeId}
                                                    onChange={GateTypeChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gateTypeId"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="Gate Number"
                                                    options={gateNumberData} placeholder="Select Gate Number"
                                                    value={gateNumber}
                                                    onChange={GateNumberChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gateNumber"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="Hoist Type"
                                                    options={hoistTypeData} placeholder="Select Hoist Type"
                                                    value={hoistTypeId}
                                                    onChange={HoistTypeChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".hoistTypeId"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="Hoist Number"
                                                    options={hoistNumberData} placeholder="Select Hoist Number"
                                                    value={hoistNumber}
                                                    onChange={HoistNumberChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".hoistNumber"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Select
                                                    name="Work Type"
                                                    options={workTypeData} placeholder="Select Work Type"
                                                    value={workType}
                                                    onChange={WorkTypeChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".workType"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    name="Program Type"
                                                    options={programTypeData} placeholder="Select Program Type"
                                                    value={programType}
                                                    onChange={ProgramTypeChangeHandler}
                                                    isSearchable
                                                    styles={selectStyles}
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".programType"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="Work Target (MT)" fullWidth value={state.worktarget || ''}
                                                    name="worktarget"
                                                    onChange={changeHandler}
                                                    id="worktarget"
                                                    required />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".worktarget"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Work Description" fullWidth value={state.workdescription || ''}
                                                    name="workdescription"
                                                    onChange={changeHandler}
                                                    id="workdescription"
                                                    required
                                                    multiline />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".workdescription"]}</div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <MDBox mt={4} mb={1} textAlign="center">
                                    {loading ? <Oval
                                        heigth="20"
                                        width="20"
                                        color='grey'
                                        ariaLabel='loading'
                                    /> :
                                        <div>
                                            <MDButton color="info" onClick={submitHandler}>
                                                SAVE
                                            </MDButton>&nbsp;&nbsp;&nbsp;&nbsp;
                                            <MDButton color="info" onClick={clearHandler}>
                                                CLEAR
                                            </MDButton>
                                        </div>
                                    }
                                    {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                </MDBox>
                            </Card>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow style={{ backgroundColor: 'lightgrey' }}>
                                                <TableCell style={{ fontWeight: 'bold' }}>Project</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Gate Type</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Gate Number</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Hoist Type</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Hoist Number</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Work Description</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Work Date</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Program Type</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Work Target</TableCell>
                                            </TableRow>
                                            <TableBody>
                                                {data.map((row) => (
                                                    <TableRow
                                                        key={row.workId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">{row.project}</TableCell>
                                                        <TableCell component="th" scope="row">{row.gateType}</TableCell>
                                                        <TableCell component="th" scope="row">{row.gateNumber}</TableCell>
                                                        <TableCell component="th" scope="row">{row.hoistType}</TableCell>
                                                        <TableCell component="th" scope="row">{row.hoistNumber}</TableCell>
                                                        <TableCell component="th" scope="row">{row.workDescription}</TableCell>
                                                        <TableCell component="th" scope="row">{moment(row.workDate).format('DD/MM/YYYY')}</TableCell>
                                                        <TableCell component="th" scope="row">{row.programType}</TableCell>
                                                        <TableCell component="th" scope="row">{row.workTarget}</TableCell>
                                                        {/* <TableCell><MDButton onClick={() => handleEdit(row.projectGateId)}>Edit</MDButton></TableCell> */}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default WorkDetails;