 //  const baseURL = 'https://localhost:44317/api/';
  const baseURL = 'https://api.egremes.in/api/';

export const LOGIN_URL = `${baseURL}Account/login`;
export const CE_URL = `${baseURL}Masters/getces`;
export const GET_CE_BY_ID = `${baseURL}Masters/getcebyid`;
export const ADD_UPDATE_CE = `${baseURL}Masters/addeditce`;
export const SE_URL = `${baseURL}Masters/getses`;
export const GET_SE_BY_CE_ID = `${baseURL}Masters/getsebyceid`;
export const GET_SE_BY_ID = `${baseURL}Masters/getsebyid`;
export const ADD_UPDATE_SE = `${baseURL}Masters/addeditse`;
export const MECHANICAL_DIVISION_URL = `${baseURL}Masters/getmechanicaldivisions`;
export const GET_MECHANICAL_DIVISION_BY_SE_ID = `${baseURL}Masters/getmechanicaldivisionbyseid`;
export const GET_MECHANICAL_DIVISION_BY_ID = `${baseURL}Masters/getmechanicaldivisionbyid`;
export const ADD_UPDATE_MECHANICAL_DIVISION = `${baseURL}Masters/addeditmechanicaldivision`;
export const MECHANICAL_SUB_DIVISION_URL = `${baseURL}Masters/getmechanicalsubdivisions`;
export const GET_MECHANICAL_SUB_DIVISION_BY_MECH_DIV_ID = `${baseURL}Masters/getmechanicalsubdivisionbymechdivid`;
export const GET_MECHANICAL_SUB_DIVISION_BY_ID = `${baseURL}Masters/getmechanicalsubdivisionbyid`;
export const ADD_UPDATE_MECHANICAL_SUB_DIVISION = `${baseURL}Masters/addeditmechanicalsubdivision`;
export const CIVIL_CEO_URL = `${baseURL}Masters/getcivilceos`;
export const GET_CIVIL_CEO_BY_ID = `${baseURL}Masters/getcivilceobyid`;
export const ADD_UPDATE_CIVIL_CEO = `${baseURL}Masters/addeditcivilceo`;
export const CIVIL_SEO_URL = `${baseURL}Masters/getcivilseos`;
export const GET_CIVIL_SEO_BY_CEO_ID = `${baseURL}Masters/getcivilseobyceoid`;
export const GET_CIVIL_SEO_BY_ID = `${baseURL}Masters/getcivilseobyid`;
export const ADD_UPDATE_CIVIL_SEO = `${baseURL}Masters/addeditcivilseo`;
export const CIVIL_DIVISION_URL = `${baseURL}Masters/getcivildivisions`;
export const GET_CIVIL_DIVISION_BY_SEO_ID = `${baseURL}Masters/getcivildivisionbyseoid`;
export const GET_CIVIL_DIVISION_BY_ID = `${baseURL}Masters/getcivildivisionbyid`;
export const ADD_UPDATE_CIVIL_DIVISION = `${baseURL}Masters/addeditcivildivision`;
export const CIVIL_SUB_DIVISION_URL = `${baseURL}Masters/getcivilsubdivisions`;
export const GET_CIVIL_SUB_DIVISION_BY_CIVIL_DIV_ID = `${baseURL}Masters/getcivilsubdivisionbycivildivid`;
export const GET_CIVIL_SUB_DIVISION_BY_ID = `${baseURL}Masters/getcivilsubdivisionbyid`;
export const ADD_UPDATE_CIVIL_SUB_DIVISION = `${baseURL}Masters/addeditcivilsubdivision`;
export const BANK_URL = `${baseURL}Masters/getbanks`;
export const GET_BANK_BY_ID = `${baseURL}Masters/getbankbyid`;
export const ADD_UPDATE_BANK = `${baseURL}Masters/addeditbank`;
export const COUNTRY_URL = `${baseURL}Masters/getcountries`;
export const GET_COUNTRY_BY_ID = `${baseURL}Masters/getcountrybyid`;
export const ADD_UPDATE_COUNTRY = `${baseURL}Masters/addeditcountry`;
export const STATE_URL = `${baseURL}Masters/getstates`;
export const GET_STATE_BY_ID = `${baseURL}Masters/getstatebyid`;
export const GET_STATE_BY_COUNTRY_ID = `${baseURL}Masters/getstatesbycountryid`;
export const ADD_UPDATE_STATE = `${baseURL}Masters/addeditstate`;
export const DISTRICT_URL = `${baseURL}Masters/getdistricts`;
export const GET_DISTRICT_BY_STATE_ID = `${baseURL}Masters/getdistrictsbystateid`;
export const GET_DISTRICT_BY_ID = `${baseURL}Masters/getdistrictbyid`;
export const ADD_UPDATE_DISTRICT = `${baseURL}Masters/addeditdistrict`;
export const DESIGNATION_URL = `${baseURL}Masters/getdesignations`;
export const GET_DESIGNATION_BY_ID = `${baseURL}Masters/getdesignationbyid`;
export const ADD_UPDATE_DESIGNATION = `${baseURL}Masters/addeditdesignation`;
export const USERS_URL = `${baseURL}Masters/getusers`;
export const GET_ACTIVE_USERS = `${baseURL}Masters/getactiveusers`;
export const GET_USER_BY_ID = `${baseURL}Masters/getuserbyid`;
export const ADD_UPDATE_USER = `${baseURL}Masters/addedituser`;
export const UPDATE_USER_STATUS = `${baseURL}Masters/updateuserstatus`;
export const WORK_STAGE_URL = `${baseURL}Masters/getworkstages`;
export const GET_WORK_STAGE_REMARK_BY_STAGE_ID = `${baseURL}Masters/getworkstageremarks`;
export const GET_WORK_STAGE_REMARK_BY_ID = `${baseURL}Masters/getworkstageremarkbyid`;
export const ADD_UPDATE_WORK_STAGE_REMARK = `${baseURL}Masters/addeditworkstageremark`;
export const PROJECT_URL = `${baseURL}Masters/getprojects`;
export const GET_PROJECT_BY_ID = `${baseURL}Masters/getprojectbyid`;
export const ADD_UPDATE_PROJECT = `${baseURL}Masters/addeditproject`;
export const GATE_TYPE_URL = `${baseURL}Masters/getgatetypes`;
export const GET_GATE_TYPE_BY_ID = `${baseURL}Masters/getgatetypebyid`;
export const ADD_UPDATE_GATE_TYPE = `${baseURL}Masters/addeditgatetype`;
export const HOIST_TYPE_URL = `${baseURL}Masters/gethoisttypes`;
export const GET_HOIST_TYPE_BY_ID = `${baseURL}Masters/gethoisttypebyid`;
export const ADD_UPDATE_HOIST_TYPE = `${baseURL}Masters/addedithoisttype`;
export const GATE_COMPONENT_URL = `${baseURL}Masters/getgatecomponents`;
export const GET_GATE_COMPONENT_BY_ID = `${baseURL}Masters/getgatecomponentbyid`;
export const ADD_UPDATE_GATE_COMPONENT = `${baseURL}Masters/addeditgatecomponent`;
export const HOIST_COMPONENT_URL = `${baseURL}Masters/gethoistcomponents`;
export const GET_HOIST_COMPONENT_BY_ID = `${baseURL}Masters/gethoistcomponentbyid`;
export const ADD_UPDATE_HOIST_COMPONENT = `${baseURL}Masters/addedithoistcomponent`;
export const PROJECT_GATE_URL = `${baseURL}Masters/getprojectgates`;
export const GET_PROJECT_GATE_BY_ID = `${baseURL}Masters/getprojectgatebyid`;
export const GET_PROJECT_GATE_TYPES = `${baseURL}Masters/getprojectgatetypes`;
export const GET_PROJECT_GATE_BY_GATE_TYPE_ID = `${baseURL}Masters/getprojectgatesbygatetypeid`;
export const ADD_UPDATE_PROJECT_GATE = `${baseURL}Masters/addeditprojectgate`;
export const PROJECT_HOIST_URL = `${baseURL}Masters/getprojecthoists`;
export const GET_PROJECT_HOIST_BY_ID = `${baseURL}Masters/getprojecthoistbyid`;
export const GET_PROJECT_HOIST_TYPES = `${baseURL}Masters/getprojecthoisttypes`;
export const GET_PROJECT_HOIST_BY_HOIST_TYPE_ID = `${baseURL}Masters/getprojecthoistsbyhoisttypeid`;
export const ADD_UPDATE_PROJECT_HOIST = `${baseURL}Masters/addeditprojecthoist`;
export const WORK_DETAILS_URL = `${baseURL}Work/getworkdetails`;
export const GET_WORK_DETAILS_BY_ID = `${baseURL}Work/getworkdetailsbyid`;
export const ADD_UPDATE_WORK_DETAILS = `${baseURL}Work/addeditworkdetails`;
export const GET_WORK_DETAILS_NOTALLOCATED_TO_SE = `${baseURL}Work/getworkdetailsnotalloctedtose`;
export const GET_WORK_ALLOCATION_TO_SE_BY_PROJECTID = `${baseURL}Work/getworkallocationtosebyprojectid`;
export const ADD_UPDATE_WORK_ALLOCATION_TO_SE = `${baseURL}Work/addeditworkallocationtose`;
export const DELETE_WORK_ALLOCATION_TO_SE = `${baseURL}Work/deleteworkallocationtose`;
export const GET_WORK_DETAILS_NOTALLOCATED_TO_MECHDIV = `${baseURL}Work/getworkdetailsnotalloctedtomechdiv`;
export const GET_WORK_ALLOCATION_TO_MECHDIV_BY_PROJECTID = `${baseURL}Work/getworkallocationtomechdivbyprojectid`;
export const ADD_UPDATE_WORK_ALLOCATION_TO_MECHDIV = `${baseURL}Work/addeditworkallocationtomechdiv`;
export const DELETE_WORK_ALLOCATION_TO_MECHDIV = `${baseURL}Work/deleteworkallocationtomechdiv`;
export const GET_WORK_DETAILS_NOTALLOCATED_TO_MECHSUBDIV = `${baseURL}Work/getworkdetailsnotalloctedtomechsubdiv`;
export const GET_WORK_ALLOCATION_TO_MECHSUBDIV_BY_PROJECTID = `${baseURL}Work/getworkallocationtomechsubdivbyprojectid`;
export const GET_WORK_ALLOCATION_TO_MECHSUBDIV_BY_SUBDIVID = `${baseURL}Work/getworkallocationtomechsubdivbysubdivid`;
export const ADD_UPDATE_WORK_ALLOCATION_TO_MECHSUBDIV = `${baseURL}Work/addeditworkallocationtomechsubdiv`;
export const DELETE_WORK_ALLOCATION_TO_MECHSUBDIV = `${baseURL}Work/deleteworkallocationtomechsubdiv`;
export const GET_WORK_TASKS = `${baseURL}Work/getworktasks`;
export const GET_WORK_TASK_BY_ID = `${baseURL}Work/getworktaskbyid`;
export const ADD_UPDATE_WORK_TASK = `${baseURL}Work/addeditworktask`;
export const GET_WORK_SUB_TASKS = `${baseURL}Work/getworksubtasks`;
export const GET_WORK_SUB_TASK_BY_ID = `${baseURL}Work/getworksubtaskbyid`;
export const ADD_UPDATE_WORK_SUB_TASK = `${baseURL}Work/addeditworksubtask`;
export const GET_WORK_SUB_SUB_TASKS = `${baseURL}Work/getworksubsubtasks`;
export const GET_WORK_SUB_SUB_TASK_BY_ID = `${baseURL}Work/getworksubsubtaskbyid`;
export const ADD_UPDATE_WORK_SUB_SUB_TASK = `${baseURL}Work/addeditworksubsubtask`;
export const GET_WORK_STATUS = `${baseURL}Work/getworkstatus`;
export const GET_WORK_STATUS_BY_ID = `${baseURL}Work/getworkstatusbyid`;
export const ADD_UPDATE_WORK_STATUS = `${baseURL}Work/addeditworkstatus`;
export const GET_PENDING_WORK_STATUS_BY_USERID = `${baseURL}Work/getpendingworkstatusbyuserid`;
export const UPDATE_WORK_STATUS = `${baseURL}Work/updateworkstatus`;
export const GET_APPROVED_REJECTED_WORK_STATUS = `${baseURL}Work/getapprovedrejectedworkstatus`;
export const DOWNLOAD_FILE = `${baseURL}Work/downloadfile`;

export default LOGIN_URL;